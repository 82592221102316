// Framework and third-party non-ui
import React, { useState, useEffect } from 'react';

// App components
import AccountTiles from './AccountTiles';

// JSON & Styles
import {
  StyledAccountDrawer,
  StyledDrawerHeader
} from './AccountDrawer-styled';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';

// Third-party components (buttons, icons, etc.)
import ArcgisAccount from 'calcite-react/ArcgisAccount';
import Button from 'calcite-react/Button';
import XIcon from 'calcite-ui-icons-react/XIcon';

function AccountDrawer() {
  // ----- Accounts Context -----
  const { selectedAccount } = useAccountsContext();

  const {
    isAccountDrawerOpen,
    openAccountDrawer,
    closeAccountDrawer
  } = useUIContext();

  const [active, setActiveAccount] = useState(selectedAccount);

  useEffect(() => {
    setActiveAccount(selectedAccount);
  }, [selectedAccount]);

  return (
    <>
      <ArcgisAccount
        // User prop checks to see if active user is logged in to know whether or not to display user's name upon being logged in or out.
        user={active.user && active.token ? active.user : {}}
        portal={active.portal || {}}
        token={active.token}
        onClick={openAccountDrawer}
      />
      <StyledAccountDrawer
        active={isAccountDrawerOpen}
        onRequestClose={closeAccountDrawer}
        drawerWidth={400}
        right
      >
        <StyledDrawerHeader>
          <Button
            onClick={closeAccountDrawer}
            icon={<XIcon size={24} />}
            iconButton
          />
        </StyledDrawerHeader>

        <AccountTiles />
      </StyledAccountDrawer>
    </>
  );
}

export default AccountDrawer;
