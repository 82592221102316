// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Redux operations and local helpers/utils/modules
import { utils as itemBrowserUtils, resources } from 'arcgis-item-browser';

// Hooks, context, and constants
import Routes from 'constants/Routes';
import { useUIContext } from 'contexts/UIContext';
import { useAccountsContext } from 'contexts/AccountsContext';

// Component specific modules (Component-styled, etc.)
import {
  StyledItemTitle,
  StyledItemImgWrapper,
  // StyledItemIcon,
  StyledItemType,
  StyledCopyToClipBoard,
  StyledButtonWrapper
} from './ItemDetails-styled';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import { CalciteA } from 'calcite-react/Elements';
import CopyToClipboard from 'calcite-react/CopyToClipboard';
import LaunchIcon from 'calcite-ui-icons-react/LaunchIcon';
import Loader from 'calcite-react/Loader';

const ItemDetails = ({ item, queryScope }) => {
  // ----- Language -----
  const { t } = useTranslation();

  // ----- Item Drawer State -----
  const { itemDrawerState, setItemDrawerState } = useUIContext();
  const initCopyItem = () => {
    setItemDrawerState({
      ...itemDrawerState,
      view: 'CopyItems'
    });
  };

  // ----- Accounts Context -----
  const { selectedAccount } = useAccountsContext();
  const isEnterprise = selectedAccount.portal.isPortal;

  // ----- Create URL Strings -----
  const { getPortalUrlFromPortal, getItemOrgPageUrl } = itemBrowserUtils.helper;
  const portalUrl =
    queryScope !== 'ONLINE'
      ? getPortalUrlFromPortal(selectedAccount?.portal)
      : process.env.REACT_APP_PORTAL_URL;

  // Item Thumbnail Url
  const [itemThumbnail, setItemThumbnail] = useState(false);

  useEffect(() => {
    const loadThumbnail = async () => {
      setItemThumbnail(false);
      const url = (await item.thumbnail)
        ? `${portalUrl}/sharing/rest/content/items/${item.id}/info/${
            item.thumbnail
          }${item.access !== 'public' ? `?token=${selectedAccount.token}` : ''}`
        : 'https://static.arcgis.com/images/desktopapp.png';

      setItemThumbnail(url);
    };

    loadThumbnail();
  }, [item, portalUrl, selectedAccount]);

  // User Profile Url
  const userProfilePageUrl = `${portalUrl}/home/user.html?user=${item.owner}`;

  // Item AGO Url
  const itemOrgPageUrl = getItemOrgPageUrl(
    queryScope === 'ONLINE' ? null : selectedAccount?.portal,
    item.id
  );

  // -----  Routing -----
  const paths = {
    json: Routes.item.children.json.path.replace(':itemId', item.id),
    resources: Routes.item.children.resources.path.replace(':itemId', item.id)
  };

  // ----- Components -----
  const copyToClipboardInput = ({ str, type }) => {
    const id = `${str} ${t('Item.ViewOnlyInput')}`;
    return (
      <StyledCopyToClipBoard>
        <label htmlFor={id}>
          {t(`Item.${type === 'url' ? 'Url' : 'ItemID'}`)}
          {type === 'url' && (
            <CalciteA
              // append the user's token to the href
              href={`${str}?token=${selectedAccount.token}`}
              target="_blank"
              data-testid="ItemView-test"
            >
              {t('Item.View')} <LaunchIcon size={12} />
            </CalciteA>
          )}
        </label>
        <CopyToClipboard fullWidth copyValue={str}>
          {str}
        </CopyToClipboard>
      </StyledCopyToClipBoard>
    );
  };

  return (
    <>
      <StyledItemImgWrapper>
        {itemThumbnail ? (
          <img src={itemThumbnail} alt={item.type} />
        ) : (
          <Loader data-testid={'LOADER'} />
        )}
      </StyledItemImgWrapper>
      <StyledItemTitle title={item.title}>{item.title}</StyledItemTitle>
      <StyledItemType>
        {resources.helper.getItemTypeIcon(item)}
        <p>
          {`${item.type} ${t('Item.By')} `}
          <CalciteA
            href={userProfilePageUrl}
            target="_blank"
            title={item.owner}
          >
            {item.owner}
          </CalciteA>
        </p>
      </StyledItemType>
      {copyToClipboardInput({ str: item.id, type: 'id' })}
      {item.url && copyToClipboardInput({ str: item.url, type: 'url' })}
      <StyledButtonWrapper>
        <Button
          className="margin-bottom"
          fullWidth
          as={Link}
          to={{ pathname: paths.json, state: { queryScope } }}
        >
          {t('Item.ViewItemJSON')}
        </Button>
        <Button
          className="margin-bottom"
          fullWidth
          as={Link}
          to={{ pathname: paths.resources, state: { queryScope } }}
        >
          {t('Item.ViewItemResources')}
        </Button>
        <Button className="margin-bottom" onClick={initCopyItem}>
          {t('Item.Copy.CopyItems', { count: 1 })}
        </Button>
        <Button fullWidth clear target="_blank" href={itemOrgPageUrl}>
          {t(
            !isEnterprise || queryScope === 'ONLINE'
              ? 'Item.OpenInAGO'
              : 'Item.OpenEnterprise'
          )}
        </Button>
      </StyledButtonWrapper>
    </>
  );
};

export default ItemDetails;
