// Framework and third-party non-ui
import React from 'react';

// Third-party components (buttons, icons, etc.)
import {
  StyledPageContainerWrapper,
  StyledPageContainer
} from './PageContainer-styled';

const PageContainer = ({
  children,
  assistantBackground, // gives the page the green geo background
  darkBackground, // background color used for json editor page
  alignCenter // aligns the content of the page in the center (ex. splash-page)
}) => {
  return (
    <StyledPageContainerWrapper
      assistantBackground={assistantBackground}
      darkBackground={darkBackground}
      data-testid={'PageContainer-Test'}
    >
      <StyledPageContainer alignCenter={alignCenter}>
        {children}
      </StyledPageContainer>
    </StyledPageContainerWrapper>
  );
};

export default PageContainer;
