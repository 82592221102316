// Framework and third-party non-ui
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  getUserTags,
  searchUsers,
  getUserContent
} from '@esri/arcgis-rest-portal';
import { request } from '@esri/arcgis-rest-request';

// Hooks, Contexts
import { useAccountManager } from '../utilities/AccountManager';

// Utils
import { getBasePath } from 'utilities/urls';

export const AccountsContext = createContext();

export const AccountsContextProvider = ({ children }) => {
  // ----- Account Manager -----
  const [accounts, setAccounts] = useState({});
  const [status, setStatus] = useState();

  //selected/unselected
  const accountsList = accounts?.list;
  const selectedAccount = accounts?.active;

  const basepath = getBasePath();

  // Allow builds to override the clientId and portalUrl
  // see https://create-react-app.dev/docs/adding-custom-environment-variables#adding-temporary-environment-variables-in-your-shell
  // for different OS variations
  const options = {
    // register an app of your own to create a unique clientId
    clientId: process.env.REACT_APP_CLIENT_ID,
    portalUrl: `${process.env.REACT_APP_PORTAL_URL}/sharing/rest`,
    redirectUri: `${basepath}/authorize`,
    params: { force_login: true }
  };

  const {
    accountManagerState,
    addAccount,
    logoutAccount,
    removeAccount,
    refreshAccount,
    switchActiveAccount,
    getUserThumbnail,
    getOrgThumbnail,
    verifyToken
  } = useAccountManager(options);

  // ----- Helpers -----
  const updateAccountStatus = useCallback(
    async account => {
      if (account?.key && account?.session) {
        // const status = await getAccountSessionStatus(account);
        const tokenExpiration = account?.session?.tokenExpires;
        const datetime = new Date();

        const tokenVerified = account?.key ? await verifyToken(account) : false;
        const tokenActive =
          tokenExpiration && tokenExpiration > datetime ? true : false;
        if (!tokenVerified || !tokenActive) {
          await logoutAccount(account);
        }
      }
    },
    [logoutAccount, verifyToken]
  );

  // ----- Effects -----
  useEffect(() => {
    const list = Object.keys(accountManagerState?.accounts).map(key => {
      let uThumb, oThumb;
      try {
        uThumb = getUserThumbnail(accountManagerState.accounts[key]);
        oThumb = getOrgThumbnail(accountManagerState.accounts[key]);
      } catch (error) {}

      const userThumbnail = uThumb?.letters
        ? uThumb
        : { letters: '!', url: null };
      const orgThumbnail = oThumb?.letters
        ? oThumb
        : { letters: '!', url: null };

      const accountItem = {
        userThumbnail,
        orgThumbnail,
        ...accountManagerState.accounts[key]
      };

      return accountItem;
    });

    list.forEach(account => {
      updateAccountStatus(account);
    });

    const activeAccount =
      accountManagerState?.accounts[accountManagerState.active];

    if (activeAccount?.user) {
      let uThumb, oThumb;
      try {
        uThumb = getUserThumbnail(activeAccount);
        oThumb = getOrgThumbnail(activeAccount);
      } catch (error) {
        uThumb = { letters: '!', url: null };
        oThumb = { letters: '!', url: null };
      }

      setAccounts({
        active: {
          key: accountManagerState.active,
          userThumbnail: uThumb,
          orgThumbnail: oThumb,
          ...activeAccount
        },
        set: accountManagerState.accounts,
        list
      });
    } else {
      setAccounts({ active: undefined, set: undefined, list: undefined });
    }
  }, [
    accountManagerState,
    getOrgThumbnail,
    getUserThumbnail,
    updateAccountStatus
  ]);

  const setSelected = account => {
    if (account?.key) {
      switchActiveAccount(account);
    }
  };

  const signIn = async (
    { portalUrl, clientId } = {},
    setActive = true,
    type = 'OAuth2'
  ) => {
    if ((portalUrl && clientId) || (portalUrl && type === 'WebTier')) {
      try {
        const url = new URL(portalUrl);
        const paths = url.pathname.split('/');

        if (!paths.includes('sharing')) {
          paths.push('sharing');
          paths.push('rest');
          url.pathname = paths.join('/');
        } else if (!paths.includes('rest')) {
          paths.push('rest');
          url.pathname = paths.join('/');
        }

        const enterpriseOptions = {
          portalUrl: url,
          clientId,
          redirectUri: options.redirectUri,
          params: { force_login: true },
          type,
          popup: !setActive ? true : false
        };
        await addAccount(enterpriseOptions, setActive, type);
      } catch (e) {
        throw new Error(`${e.name}: ${e.message}`);
      }
    } else {
      await addAccount(
        !setActive ? { ...options, popup: true } : null,
        setActive
      );
    }
  };

  const signOut = async account => {
    if (account?.key) await logoutAccount(account);

    if (account?.key === accountManagerState?.active) {
      const unselectedAccounts = getUnselectedAccountsList();
      if (unselectedAccounts) {
        const findActive = await Promise.all(
          unselectedAccounts.map(
            async unselectedAccount =>
              await getAccountSessionStatus(unselectedAccount)
          )
        );
        const activeIndex = findActive.findIndex(result => result);
        if (activeIndex >= 0 && activeIndex < unselectedAccounts.length) {
          switchActiveAccount(unselectedAccounts[activeIndex]);
        } else {
          //return to signIn
          setStatus('signOut');
        }
      } else {
        //return to signIn
        setStatus('signOut');
      }
    }
  };

  const signOutAll = async () => {
    Object.entries(accounts.set).map(([key, account]) => {
      signOut(account);
      return key;
    });
    setStatus('signOut');
  };

  const remove = account => {
    if (account?.key) {
      if (accountManagerState.active === account.key) {
        const replace = accounts.list.filter(item => item.key !== account.key);
        if (replace.length > 0) switchActiveAccount(replace[0]);
      }
      removeAccount(account);
    }
  };

  const removeAll = () => {
    Object.entries(accounts.set).map(([key, account]) => {
      remove(account);
      return key;
    });
    setStatus('remove');
  };

  const refresh = async (account = null, popup = false) => {
    const { portal } = account || {};

    if (portal?.isPortal === false && portal?.appInfo === undefined) {
      account.portal.appInfo = { appId: options?.clientId };
    }
    if (popup) {
      accountManagerState.status.authProps = {
        ...accountManagerState.status.authProps,
        popup
      };
    }
    refreshAccount(account);
    setStatus();
  };

  const getAccountSessionStatus = useCallback(
    async account => {
      const session = account?.session;

      const tokenExpires = session?.tokenExpires;
      const datetime = new Date();

      const check = account?.key ? await verifyToken(account) : false;
      const status = tokenExpires && tokenExpires > datetime ? true : false;
      return check && status;
    },
    [verifyToken]
  );

  const getOriginRoute = useCallback(() => {
    const { originRoute } = accountManagerState?.status || {};
    try {
      const path = new URL(originRoute).pathname;
      return path;
    } catch (e) {
      console.error(`Error retrieving originPath. ${e}`);
      return undefined;
    }
  }, [accountManagerState.status]);

  const getUnselectedAccountsList = useCallback(() => {
    const unselectedAccounts = accounts.list.filter(
      item => item.user !== accounts.active.user
    );
    return unselectedAccounts;
  }, [accounts]);

  const getContextStatus = useCallback(() => {
    return status;
  }, [status]);

  // ----- Account Selectors -----
  const selectAccountByOrg = useCallback(
    orgUrl => {
      const accountsList = accountManagerState?.accounts
        ? Object.values(accountManagerState?.accounts)
        : [];
      const filteredAccounts = accountsList?.filter(account => {
        if (account?.portal?.portalName?.toLowerCase() === 'arcgis online') {
          return orgUrl
            .toLowerCase()
            .includes(account?.portal?.urlKey?.toLowerCase());
        } else {
          return account?.portal?.portalHostname
            ?.toLowerCase()
            .includes(orgUrl.toLowerCase());
        }
      });
      if (filteredAccounts?.length > 0) {
        const select = filteredAccounts.find(
          account => account?.portal?.access === 'admin'
        );
        return select ? select : filteredAccounts[0];
      }
      return undefined;
    },
    [accountManagerState]
  );

  // ----- Account Item Helpers -----
  // https://app.lucidchart.com/documents/edit/57e1c41f-d7f3-459e-96ec-c9781a15e65c/0_0?shared=true

  //Returns a promise, is there a different way to access user folders?
  const getUserFolders = useCallback(async account => {
    const authentication = account?.session;
    if (!authentication) return [];
    try {
      const folders = await getUserContent({ authentication });
      return folders?.folders;
    } catch (e) {
      console.error(`Failed to get user folders with getUserContent. ${e}`);
      return [];
    }
  }, []);

  //Returns a promise, is there a different way to access org users?
  //Use paging or set num to large value?
  const getOrgUsers = useCallback(async account => {
    //https://esri.github.io/arcgis-rest-js/api/portal/searchUsers/
    const authentication = account?.session;
    if (!authentication) return [];

    const search = async (start, results, authentication) => {
      const list = searchUsers({ authentication, start })
        .then(res => {
          const nextStart = res?.nextStart;
          const result = [...results, ...res.results];

          return nextStart && nextStart > 0
            ? search(nextStart, result, authentication)
            : result;
        })
        .catch(err => {
          console.error(
            `Failed to get org users with searchUsers at start: ${start}. ${err}`
          );
          return [];
        });

      return list;
    };

    const users = await search(1, [], authentication);
    return users;
  }, []);

  //Returns a promise, is there a different way to access org tags?
  const getOrgTags = useCallback(async account => {
    //https://esri.github.io/arcgis-rest-js/api/portal/getUserTags/
    //Tested as tags from My Content -> Tags
    const authentication = account?.session;
    if (!authentication) return [];

    try {
      const response = await getUserTags({ authentication });
      const tags = response?.tags?.map(tag => tag.tag);
      return tags.length ? tags : [];
    } catch (e) {
      console.error(`Failed to get org tags with getUserTags. ${e}`);
      return [];
    }
  }, []);

  //Returns a promise, is there a different way to access categories?
  const getOrgCategories = useCallback(
    //https://developers.arcgis.com/rest/users-groups-and-items/category-schema.htm
    //Tested as tags from My Content -> Categories
    async account => {
      const portalUrl = account?.session.portal;
      const portalId = account?.portal.id;
      const token = account?.token;
      if (!portalUrl || !portalId || !token) return [];

      const url = `${portalUrl}/portals/${portalId}/categorySchema?f=json&token=${token}`;
      try {
        const response = await request(url);
        const categorySchema = response.categorySchema;
        let categories = [];
        for (let i = 0; i < categorySchema.length; i++) {
          const category = categorySchema[i].categories.map(c => c.title);
          categories = [...categories, ...category];
        }
        return categories;
      } catch (e) {
        console.error(
          `Failed to get org categories with request: ${url}. ${e}`
        );
        return [];
      }
    },
    []
  );

  const getOrgGroups = useCallback(account => {
    //Tested as groups from My Groups
    const groups = account?.user?.groups;
    return groups;

    // or
    // https://esri.github.io/arcgis-rest-js/api/portal/searchGroups/
    // import { searchGroups } from "@esri/arcgis-rest-portal";
    // searchGroups('').then(response) // response.total => 355
  }, []);

  const getOrgVersion = useCallback(account => {
    const version = account?.portal?.currentVersion;
    return version;
  }, []);

  // ----- Local Storage Enterprise -----
  const storeEnterpriseOptions = ({ portalUrl, clientId } = {}) => {
    if (portalUrl && clientId) {
      const localStorage = window.localStorage;
      const enterpriseOptions = getEnterpriseOptions();
      let storeOptions = [];

      const urlMatch = enterpriseOptions
        ? enterpriseOptions.findIndex(
            enterprise => enterprise?.portalUrl === portalUrl
          )
        : -1;
      if (urlMatch > -1) {
        storeOptions = enterpriseOptions;
        storeOptions[urlMatch] = { portalUrl, clientId };
      } else {
        storeOptions = enterpriseOptions
          ? [...enterpriseOptions, { portalUrl, clientId }]
          : [{ portalUrl, clientId }];
      }
      localStorage.setItem(
        'enterprise-form-options',
        JSON.stringify(storeOptions)
      );
    }
  };

  const getEnterpriseOptions = () => {
    const localStorage = window.localStorage;
    const enterpriseOptions = localStorage.getItem('enterprise-form-options');

    try {
      const options = enterpriseOptions ? JSON.parse(enterpriseOptions) : [];
      return options;
    } catch (e) {
      return [];
    }
  };

  return (
    <AccountsContext.Provider
      value={{
        accountsList,
        selectedAccount,
        setSelected,
        signIn,
        signOut,
        signOutAll,
        remove,
        removeAll,
        refresh,
        getUnselectedAccountsList,
        selectAccountByOrg,
        getAccountSessionStatus,
        getContextStatus,
        getOriginRoute,
        getUserFolders,
        getOrgUsers,
        getOrgTags,
        getOrgCategories,
        getOrgGroups,
        getOrgVersion,
        storeEnterpriseOptions,
        getEnterpriseOptions
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccountsContext = () => {
  const accountsContext = useContext(AccountsContext);
  if (!accountsContext)
    throw new Error(
      'Cannot use `useAccountsContext` outside of a AccountsContextProvider'
    );
  return accountsContext;
};
