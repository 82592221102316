import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react';
import { updateItem, updateItemResource } from 'arcgis-item-engine';
import { getItem, getItemData } from '@esri/arcgis-rest-portal';

import { AccountsContext } from './AccountsContext';
import {
  resourceFileTypes,
  fileExtensions
} from '../pages/ItemPages/ItemResourcesPage/utils/helper';

export const ItemContext = createContext();

export const ItemContextProvider = ({ children }) => {
  const [loadItemDetails, setLoadItemDetails] = useState({
    itemId: null,
    portal: null
  });
  const [itemData, setItemData] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [isContextReady, setIsContextReady] = useState(false);
  const [itemEditable, setItemEditable] = useState(false);
  const [itemAccessible, setItemAccessible] = useState(true);

  const { selectedAccount } = useContext(AccountsContext);
  const authentication = selectedAccount?.session;

  const defaultSelectedItemResourceEditorText = {
    content: null,
    type: ''
  };

  const [
    selectedItemResourceEditorText,
    setSelectedItemResourceEditorText
  ] = useState(defaultSelectedItemResourceEditorText);

  const clearSelectedItemResourceEditorText = () => {
    setSelectedItemResourceEditorText(defaultSelectedItemResourceEditorText);
  };

  const [selectedItemResource, setSelectedItemResource] = useState('');

  useEffect(() => {
    // ----- Arcgis Online -----
    const getJson = async () => {
      setIsContextReady(false);
      let auth = loadItemDetails.portal
        ? {
            ...authentication,
            portal: loadItemDetails.portal
          }
        : { authentication };

      try {
        const description = await getItem(loadItemDetails.itemId, auth);

        setItemDescription(description);
        // See itemControl property documented here: https://developers.arcgis.com/rest/users-groups-and-items/item.htm
        const acceptedItemPrivileges = ['admin', 'owner', 'update'];

        const itemPrivileges = description?.itemControl;
        if (acceptedItemPrivileges.includes(itemPrivileges)) {
          setItemEditable(true);
        } else {
          setItemEditable(false);
        }
        setItemAccessible(true);
      } catch (e) {
        console.warn(`Could not retrieve item description json. ${e}`);
        setItemDescription({});
        setItemAccessible(false);
      }
      try {
        const data = await getItemData(loadItemDetails.itemId, auth);
        setItemData(data);
      } catch (e) {
        console.warn(`Could not retrieve item data json. ${e}`);
        setItemData({});
      }

      setIsContextReady(true);
    };

    if (loadItemDetails.itemId) {
      getJson();
    }
  }, [authentication, loadItemDetails]);

  const setItemJson = ({ jsonString, type }) => {
    switch (type) {
      case 'description':
        setItemDescription(jsonString);
        return true;
      case 'data':
        setItemData(jsonString);
        return true;
      default:
        return false;
    }
  };

  const saveItemEdits = useCallback(
    async ({ authentication, id, type, jsonString }) => {
      // disable further edits
      // editor.updateOptions({ readOnly: true });
      if (isContextReady) {
        // save edits to portal
        try {
          const valueObj = JSON.parse(jsonString);
          let updateOperation;
          if (type === 'data') {
            updateOperation = updateItem(
              { authentication, id, owner: itemDescription.owner },
              { data: { text: valueObj } }
            );
            setItemData(valueObj);
          } else {
            updateOperation = updateItem(
              { authentication, id, owner: itemDescription.owner },
              { description: valueObj }
            );

            setItemDescription(valueObj);
          }
          return await updateOperation;
        } catch (e) {
          console.error(`Error saving edits. ${e}`);
          throw e;
        }
      }
      return Promise.reject();
    },
    [isContextReady]
  );

  const saveItemResourceEdits = useCallback(
    async ({ authentication, id, resourceName, type, editorValue }) => {
      // disable further edits
      // editor.updateOptions({ readOnly: true });
      if (isContextReady) {
        // save edits to portal
        try {
          const itemParams = {
            authentication,
            id,
            owner: itemDescription.owner
          };
          const pathSegments = resourceName.split('/');
          const fileName = pathSegments.pop();
          const prefix = pathSegments.join('/');

          // Set editor text state to revert back to if user cancels future edits
          let itemResourceText = {};
          let content;
          let key = 'content';

          switch (type) {
            case fileExtensions.json:
              // If editorValue is JSON, parse to make sure editor has valid JSON.  If it does not, catch will run and prevent invalid JSON from being saved.
              content = JSON.parse(editorValue);
              break;

            case fileExtensions.xml:
              const parser = new DOMParser();
              const xmlString = editorValue;
              key = 'resource';
              content = parser.parseFromString(
                xmlString,
                resourceFileTypes.xml
              );

              // Validates XML (currently Monaco editor does not support highlighting of syntax errors for XML files: https://github.com/microsoft/monaco-editor/issues/1914)
              const errorExists =
                content.getElementsByTagName('parsererror').length > 0;

              if (errorExists) {
                throw new Error('Invalid XML. Please correct before saving.');
              }
              break;

            case fileExtensions.txt:
              content = editorValue;
              break;

            default:
              content = await editorValue;
          }

          itemResourceText = { content, type };

          setSelectedItemResourceEditorText(itemResourceText);

          const update = {
            name: fileName,
            prefix
          };

          if (type === fileExtensions.xml) {
            let file = new Blob([editorValue], {
              type: resourceFileTypes.xml,
              lastModified: new Date()
            });

            file.name = fileName;
            update[key] = file;
          } else {
            update[key] = editorValue;
          }

          const updateOperation = await updateItemResource(itemParams, update);

          return await updateOperation;
        } catch (e) {
          console.error(`Error saving edits. ${e}`);
          throw e;
        }
      }
      return Promise.reject();
    },
    [isContextReady, itemDescription.owner]
  );

  return (
    <ItemContext.Provider
      value={{
        loadItemDetails,
        setLoadItemDetails,
        isContextReady,
        setIsContextReady,
        itemData,
        itemDescription,
        setItemJson,
        saveItemEdits,
        saveItemResourceEdits,
        itemEditable,
        itemAccessible,
        setItemAccessible,
        selectedItemResourceEditorText,
        setSelectedItemResourceEditorText,
        clearSelectedItemResourceEditorText,
        authentication,
        selectedItemResource,
        setSelectedItemResource
      }}
    >
      {children}
    </ItemContext.Provider>
  );
};

export const useItemContext = () => useContext(ItemContext);
