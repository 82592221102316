// Framework and third-party non-ui
import styled from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Drawer from 'calcite-react/Drawer';

const StyledItemDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const StyledItemDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  h1 {
    margin: 0;
  }
`;

const StyledItemDrawerBody = styled.div`
  padding: 0 1.5rem;
  flex-grow: 1;
`;

export { StyledItemDrawer, StyledItemDrawerHeader, StyledItemDrawerBody };
