// Framework and third-party non-ui
import styled from 'styled-components';

const StyledSignInPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledIntro = styled.div`
  width: 60%;
  color: var(--calcite-ui-text-inverse);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeInAndDown 1s;
  h1 {
    font-size: var(--calcite-font-size-6);
    font-weight: var(--calcite-font-weight-normal);
    margin-top: 0;
    margin-bottom: 3.5rem;
  }
  @media (max-width: var(--screen-size-small)) {
    h1 {
      display: none;
    }
  }
  @media (max-width: var(--screen-size-medium)) {
    width: 70%;
    h1 {
      font-size: var(--calcite-font-size-8);
      margin-bottom: 2rem;
    }
  }
`;

const StyledDivider = styled.div`
  background: var(--calcite-ui-foreground-2);
  margin-bottom: 3rem;
  height: 2px;
  width: 25%;
  @media (max-width: var(--screen-size-medium)) {
    margin-bottom: 1.5rem;
  }
  @media (max-width: var(--screen-size-small)) {
    display: none;
  }
`;

const StyledDescriptionContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 3rem;
  @media (max-width: var(--screen-size-medium)) {
    margin-bottom: 2.25rem};
  }
  @media (max-width: var(--screen-size-small)) {
    flex-direction: column;
  }
`;

const StyledDescriptionItem = styled.span`
  text-align: left;
  label {
    font-size: var(--calcite-font-size-1);
    font-weight: var(--calcite-font-weight-bold);
  }
  p {
    color: var(var(--calcite-ui-foreground-2));
    margin: 0.5rem 0 0 0;
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media (max-width: var(--screen-size-medium)) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

export {
  StyledSignInPage,
  StyledIntro,
  StyledDivider,
  StyledDescriptionContainer,
  StyledDescriptionItem,
  StyledButtonContainer
};
