// Framework and third-party non-ui
import React, { useState, useEffect } from 'react';

// Hooks, context, and constants
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useUIContext } from 'contexts/UIContext';
import { deployment } from 'config';
import Routes from 'constants/Routes';
// Calcite Components
import {
  CalciteIcon,
  CalciteInput
} from '@esri/calcite-components-react';
// App components
import PageContainer from 'pages/PageContainer';
import ViewPopover from 'components/ViewPopover';
import Toolbar from 'components/Toolbar';

// JSON & Styles
import 'react-virtualized/styles.css';
import {
  StyledItemFilterWrapper,
  StyledItemFilter,
  ItemTableWrapper,
  StyledItemLink,
  StyledButtonWrapper,
  StyledFilterButton
} from './ContentPage-styled';

// Third-party components (buttons, icons, etc.)
import { CalciteA } from 'calcite-react/Elements';
import SubNav, { SubNavList, SubNavLink } from 'calcite-react/SubNav';
import {
  useItemBrowserState,
  ItemTable,
  ItemList,
  ItemGrid,
  SortPopover,
  utils
} from 'arcgis-item-browser';

const ContentPage = ({ userAccount }) => {
  // ----- Language -----
  const { t } = useTranslation();
  const langRef = 'Pages.Content.SubPages';

  // ----- Routing -----
  const history = useHistory();
  const location = useLocation();

  const browserViewStrings = {
    USER: 'content',
    ORGANIZATION: 'organization',
    ONLINE: 'online'
  };

  // ----- ItemDrawer State -----
  const { openItemDrawer } = useUIContext();

  // Development implementation of URLSearchParams
  const searchParams = new URLSearchParams(window.location.search);

  const [currentView, setCurrentView] = useState('table');

  // ----- SubNav State ----
  const [browserView, setBrowserView] = useState(
    browserViewStrings[searchParams.get('queryScope')]
  );

  // If search params change query scope, change browser view accordingly
  useEffect(() => {
    if (browserViewStrings[searchParams.get('queryScope')] !== browserView)
      setBrowserView(browserViewStrings[searchParams.get('queryScope')]);
  }, [searchParams, browserViewStrings, browserView]);

  const isEnterprise =
    userAccount?.portal?.isPortal && browserView !== 'online';

  // Func we call from hook whenever urlParams obj changes
  const onUrlParamsUpdated = newParams => {
    if (!newParams.get('queryScope')) {
      newParams.append('queryScope', 'USER');
    }

    if (location.search !== `?${newParams.toString()}`) {
      history.replace(`${history.location.pathname}?${newParams.toString()}`);
    }
  };

  // Pass an options object containing the following properties.
  // Properties showFolderFilter and showUserFilter determine which browser
  // views should show each specified filter.  Query scope refers to whether
  // USER, ORGANIZATION, or ONLINE items are shown.

  /* For optional customCellRenderer, pass an object consisting of a key that matches the data key of the column for which to use the custom render function and a value consisting of a function to act as a custom cell renderer for specified column in ItemTable.

  Ex.
    const customCellRenderer = { title: ({
          cellData,
          columnData,
          columnIndex,
          dataKey,
          isScrolling,
          rowData,
          rowIndex,
          getTypeImage,
        }) => {
      return <h6 style={{ color: "red" }}>{cellData}</h6>;
    }
  };
  */

  const customCellRenderer = {
    title: ({ cellData, rowData, getItemTypeIcon }) => {
      return (
        <StyledItemLink>
          {getItemTypeIcon(rowData)}
          <CalciteA
            title={cellData}
            onClick={() =>
              openItemDrawer({
                items: [rowData]
              })
            }
          >
            {cellData}
          </CalciteA>
        </StyledItemLink>
      );
    }
  };

  const customTitleRenderer = (title, item) => {
    return (
      <StyledItemLink>
        <CalciteA
          title={title}
          onClick={() =>
            openItemDrawer({
              items: [item]
            })
          }
        >
          {title}
        </CalciteA>
      </StyledItemLink>
    );
  };

  const itemBrowserOptions = {
    showFolderFilter: searchParams.get('queryScope') === 'USER',
    showUserFilter: searchParams.get('queryScope') === 'USER',
    queryScope: searchParams.get('queryScope') || 'USER',
    urlSearchParams: searchParams,
    onUrlParamsUpdated,
    customCellRenderer
  };

  const {
    queryResults,
    searchTermProps,
    ...itemBrowserProps
  } = useItemBrowserState(userAccount, itemBrowserOptions);
  const getItemById = id => {
    return queryResults.results.find(item => item.id === id);
  };

  // Default actions to be passed to ItemFilter
  const copyItem = {
    id: 'copy',
    label: t('Item.Copy.CopyItems', {
      count: 1
    }),
    icon: <CalciteIcon icon={'copy'}/>,
    onClick: (itemIds = itemBrowserProps.selectedItemIds) => {
      openItemDrawer({
        view: 'CopyItems',
        items: Array.isArray(itemIds?.items)
          ? itemIds?.items.map(id => getItemById(id))
          : [getItemById(itemIds)],
        onActionComplete: () => {
          // Refresh browser after copy item completes
          setTimeout(
            () => itemBrowserProps.handleQueryChange({ newActionUpdate: true }),
            600
          );
        }
      });
    }
  };
  const multiSelectActions = [
    {
      ...copyItem,
      label: t('Item.Copy.CopyItems', {
        count: 2
      })
    },
    utils.actions.default.moveItem,
    utils.actions.default.shareItem,
    utils.actions.default.deleteItem
  ];

  // Only keeping Favorite action for UI purposses currently
  // Can add back the other actions as we implement them
  const singleItemActions = [
    {
      id: 'viewItemJson',
      label: t('Item.ViewItemJSON'),
      icon:<CalciteIcon icon={'brackets-curly'}/>,
      onClick: itemId => {
        history.push({
          pathname: Routes.item.children.json.path.replace(':itemId', itemId),
          state: { queryScope: searchParams.get('queryScope') }
        });
      }
    },
    {
      id: 'viewItemDetails',
      label: t('Item.ViewItemDetails'),
      icon: <CalciteIcon icon={'feature-details'} size={16} />,
      onClick: itemId => {
        openItemDrawer({
          items: [getItemById(itemId)]
        });
      }
    },
    {
      id: 'showItemResources',
      label: t('Item.ViewItemResources'),
      icon: <CalciteIcon icon={'content-inline'} size={16} />,
      onClick: itemId => {
        history.push({
          pathname: Routes.item.children.resources.path.replace(
            ':itemId',
            itemId
          ),
          state: { queryScope: searchParams.get('queryScope') }
        });
      }
    },
    {
      id: 'openInAGO',
      label: t(!isEnterprise ? 'Item.OpenInAGO' : 'Item.OpenEnterprise'),
      icon: <CalciteIcon icon={'launch'} size={16} />,
      onClick: itemId => {
        window.open(
          `${utils.helper.getItemOrgPageUrl(
            searchParams.get('queryScope') === 'ONLINE'
              ? null
              : userAccount.portal,
            itemId
          )}`,
          '_blank'
        );
      }
    },
    copyItem
  ];

  const [areFiltersVisible, setAreFiltersVisible] = useState(true);
  const toggleFilters = () => setAreFiltersVisible(!areFiltersVisible);

  // Handles setting scope (ex. My Content, My Organization, ArcGIS Online)
  const handleSetContentFilter = e => {
    setBrowserView(e.target.name);

    // Set query scope to url params
    let params = new URLSearchParams(window.location.search);
    params.set('queryScope', e.target.dataset.scope);
    window.history.replaceState({}, '', `?${params.toString()}`);
  };

  return (
    <>
      {/* --------- Sub Nav --------- */}
      <SubNav backgroundColor="var(--assistant-green)">
        <SubNavList>
          <SubNavLink
            active={browserView === 'content'}
            name={'content'}
            data-scope={'USER'}
            onClick={handleSetContentFilter}
          >
            {t(`${langRef}.UserContent.Title`)}
          </SubNavLink>
          <SubNavLink
            active={browserView === 'organization'}
            name={'organization'}
            data-scope={'ORGANIZATION'}
            onClick={handleSetContentFilter}
          >
            {t(`${langRef}.OrgContent.Title`)}
          </SubNavLink>
          {!deployment.offlineMode && (
            <SubNavLink
              active={browserView === 'online'}
              name={'online'}
              data-scope={'ONLINE'}
              onClick={handleSetContentFilter}
            >
              {t(`${langRef}.AGOContent.Title`)}
            </SubNavLink>
          )}
        </SubNavList>
      </SubNav>

      <Toolbar
        centerContent={
          <CalciteInput
            clearable
            icon="search"
            name={'folder-search'}
            label={'search'}
            placeholder="Filter items..."
            style={{ border: 'none' }}
            value={searchTermProps.value}
            onCalciteInputInput={searchTermProps?.onCalciteInputInput}
          />
        }
        rightContent={
          <StyledButtonWrapper>
            <ViewPopover
              currentView={currentView}
              setCurrentView={setCurrentView}
            />
            <SortPopover
              {...itemBrowserProps}
              customStyle
              white
              small
              style={{ marginLeft: '0.25rem' }}
            />
            <StyledFilterButton
              white
              small
              style={{ marginLeft: '0.25rem' }}
              areFiltersVisible={areFiltersVisible}
              onClick={toggleFilters}
              icon={<CalciteIcon icon={'sliders'} size={16} />}
              iconPosition="before"
            >
              {t('App.Filter')}
            </StyledFilterButton>
          </StyledButtonWrapper>
        }
      />

      {/* --------- Content Page --------- */}
      <PageContainer>
        <StyledItemFilterWrapper>
          {areFiltersVisible && <StyledItemFilter {...itemBrowserProps} />}
          <ItemTableWrapper>
            {currentView === 'grid' ? (
              <ItemGrid
                queryResults={queryResults}
                actions={multiSelectActions}
                singleItemActions={singleItemActions}
                browserView={browserView}
                customTitleRenderer={customTitleRenderer}
                {...itemBrowserProps}
              />
            ) : currentView === 'list' ? (
              <ItemList
                queryResults={queryResults}
                actions={multiSelectActions}
                singleItemActions={singleItemActions}
                browserView={browserView}
                customTitleRenderer={customTitleRenderer}
                {...itemBrowserProps}
              />
            ) : (
              <ItemTable
                queryResults={queryResults}
                actions={multiSelectActions}
                singleItemActions={singleItemActions}
                browserView={browserView}
                {...itemBrowserProps}
              />
            )}
          </ItemTableWrapper>
        </StyledItemFilterWrapper>
      </PageContainer>
    </>
  );
};

export default ContentPage;
