// Framework and third-party non-ui
import styled from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Drawer from 'calcite-react/Drawer';
import Button from 'calcite-react/Button';
import AccountTile from 'calcite-react/AccountTile';

const StyledAccountDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const StyledDrawerHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
  padding-bottom: 0;
  flex-wrap: wrap;
  background-color: var(--calcite-ui-foreground-1);
  z-index: 9;
`;

const StyledDrawerBody = styled.div`
  overflow: auto;
  margin: 0
    ${props =>
      !props.isAccountDrawer ? '-1.5rem' : 0};
  padding: 0 1.5rem;
  padding-bottom: 0;
  margin-top: ${props => !props.isAccountDrawer && '1rem'};
  flex-basis: 100%;
`;

const StyledDrawerFooter = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem
    ${props => (props.isAccountDrawer ? '1.5rem' : 0)};
  background-color: var(--calcite-ui-foreground-1);
`;

const StyledAccountSectionHeader = styled.h4`
  font-size: var(--calcite-font-size--2);
  font-weight: var(--calcite-font-weight-normal);
  margin-bottom: 0.25rem;
  color: var(--calcite-ui-text-3);
`;

const StyledAccountTile = styled(AccountTile)`
  padding: 0.75rem 1.5rem;
  margin: 0 -1.5rem;
  ${props => props.hideAuthentication && { marginRight: '0.5rem' }};
  outline-offset: -3px;
  background-color: ${props =>
    props.selected ? `var(--calcite-ui-foreground-current) !important` : 'transparent'};
  outline: ${props =>
    props.selected ? `3px solid var(--calcite-ui-brand)` : 'none'};
`;

const StyledDividingLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--calcite-ui-border-3);
  margin: 0.75rem 0;
`;

const StyledSignOutButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
  }
`;

const StyledPopoverWrapper = styled.div`
  margin: 1rem 0 0.25rem auto;
`;

export {
  StyledAccountDrawer,
  StyledDrawerHeader,
  StyledDrawerBody,
  StyledDrawerFooter,
  StyledAccountSectionHeader,
  StyledButtonWrapper,
  StyledAccountTile,
  StyledDividingLine,
  StyledSignOutButton,
  StyledPopoverWrapper
};
