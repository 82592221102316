import styled, { css } from 'styled-components';

import { TopNavLink } from 'calcite-react/TopNav';

export const StyledTopNavLink = styled(TopNavLink)`
  font-size: var(--calcite-font-size--1);
  ${props =>
    props.margin &&
    css`
      margin: 0 1.5rem;
    `};
`;
