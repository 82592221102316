// Framework and third-party non-ui
import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';

// Hooks, context, and constants
import Routes from 'constants/Routes';
import { useAccountsContext } from 'contexts/AccountsContext';

// App pages & components
import ContentPage from 'pages/ContentPage';
import ComingSoonPage from 'pages/ComingSoonPage';
import ItemPagesContainer from 'pages/ItemPages';
import SandboxPage from 'pages/SandboxPage';
import NotFoundPage from 'pages/NotFoundPage';
import SignInPage from 'pages/SignInPage';
import Authorize from 'pages/Authorize';
import ItemDrawer from 'components/ItemDrawer';
import Header from 'components/Header';
import EnterpriseSignInModal from 'components/EnterpriseSignInModal';
import RouteAuthentication from 'components/RouteAuthentication';
import '@esri/calcite-components/dist/calcite/calcite.css';

// javascript:(function(){​​​​window.open("http://localhost:3000/?openUrl="+window.location.href);}​​​​())
const App = () => {
  const {
    selectedAccount,
    getContextStatus,
    selectAccountByOrg,
    setSelected
  } = useAccountsContext();
  const contextStatus = getContextStatus();
  const redirectSignIn =
    contextStatus === 'signOut' || contextStatus === 'remove';

  // ----- Bookmark Redirect -----

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const openUrl = searchParams.get('openUrl');

  if (openUrl) {
    const url = new URL(openUrl);
    const itemId = new URLSearchParams(url.search).get('id');
    const orgUrl = url.hostname;
    if (orgUrl && itemId) {
      const setAccount = selectAccountByOrg(orgUrl);
      if (setAccount) {
        if (selectedAccount?.key !== setAccount?.key) {
          setSelected(setAccount);
        }
      }
      history.push(`/item/${itemId}`);
    }
  }

  return (
    <>
      <Header />

      <EnterpriseSignInModal />

      <ItemDrawer />

      {/* --------- Routes --------- */}
      <Switch>
        <Route exact path="/">
          <Redirect to={Routes.signin.path} />
        </Route>

        <Route path={Routes.authorize.path} component={Authorize} />
        {/* Re-route if signed in and active token */}
        <Route path={Routes.signin.path} component={SignInPage} />
        <Route path={Routes.comingSoon.path} component={ComingSoonPage} />

        {/* Authorized Components */}
        <Route path={Routes.content.path}>
          <RouteAuthentication
            account={selectedAccount}
            redirectSignIn={redirectSignIn}
          >
            <ContentPage userAccount={selectedAccount} />
          </RouteAuthentication>
        </Route>

        <Route path={Routes.sandbox.path}>
          <RouteAuthentication
            account={selectedAccount}
            redirectSignIn={redirectSignIn}
          >
            <SandboxPage />
          </RouteAuthentication>
        </Route>

        <Route path={Routes.item.path}>
          <RouteAuthentication
            account={selectedAccount}
            redirectSignIn={redirectSignIn}
          >
            <ItemPagesContainer />
          </RouteAuthentication>
        </Route>

        <Route>
          <RouteAuthentication
            account={selectedAccount}
            redirectSignIn={redirectSignIn}
          >
            <NotFoundPage />
          </RouteAuthentication>
        </Route>
      </Switch>
    </>
  );
};

export default App;
