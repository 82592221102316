// Framework and third-party non-ui
import React from 'react';
import { useTranslation } from 'react-i18next';

// App components
import PageContainer from '../PageContainer';

// JSON & Styles
import { StyledComingSoonPage, StyledIntro } from './ComingSoonPage-styled';

// Third-party components (buttons, icons, etc.)
import logo from './logo-white.png';

const ComingSoonPage = props => {
  const { t } = useTranslation();

  return (
    <PageContainer assistantBackground alignCenter>
      <StyledComingSoonPage>
        <StyledIntro>
          <div>{t('Pages.ComingSoon.Title')}</div>
          <img src={logo} alt="Esri" />
        </StyledIntro>
      </StyledComingSoonPage>
    </PageContainer>
  );
};

export default ComingSoonPage;
