// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useParams,
  useLocation
} from 'react-router-dom';

// Hooks, context, and constants
import Routes from 'constants/Routes';
import { useItemContext } from 'contexts/ItemContext';

// App components
import ItemSubNav from './ItemSubNav/ItemSubNav';
import NotFoundPage from 'pages/NotFoundPage';
import JsonPage from './JsonPage';
import ItemResourcesEditor from './ItemResourcesPage/ItemResourcesEditor';
import ItemResourcesPage from 'pages/ItemPages/ItemResourcesPage';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

// App Specific

const ItemPagesContainer = () => {
  // -----  Routing -----
  const { itemId } = useParams();
  const { pathname, state, search } = useLocation();
  const [routerState] = useState(state);

  let params = new URLSearchParams(window.location.search);
  const paramsScope = params.get('queryScope');
  const [queryScope, setQueryScope] = useState(
    routerState?.queryScope || paramsScope || 'USER'
  );

  // Check for and update queryScope
  useEffect(() => {
    if (!paramsScope && queryScope) {
      params.set('queryScope', queryScope);
      window.history.replaceState({}, '', `?${params.toString()}`);
    } else if (paramsScope) {
      setQueryScope(paramsScope);
    }
  }, [pathname]);

  // ----- Item Context -----
  const { setLoadItemDetails } = useItemContext();

  const paths = {
    content: Routes.content.path, // eventually will be a link to previous page
    json: Routes.item.children.json.path.replace(':itemId', itemId),
    resources: Routes.item.children.resources.path.replace(':itemId', itemId)
  };

  useEffect(() => {
    setLoadItemDetails({
      portal:
        queryScope === 'ONLINE'
          ? `${process.env.REACT_APP_PORTAL_URL}/sharing/rest`
          : null,
      itemId
    });
  }, [itemId]);

  return (
    <>
      <ItemSubNav paths={paths} queryScope={queryScope} />
      <Switch>
        <Route exact path={`/item/${itemId}`}>
          <Redirect to={paths.json} />
        </Route>
        <Route path={paths.json}>
          <JsonPage itemId={itemId} />
        </Route>
        <Route
          path={paths.resources}
          render={props => {
            const query = new URLSearchParams(search);
            const resourceName = query.get('file');
            // If current location in browser contains query with key "file", render item resource editor for that resource. Otherwise, render full page of all item's resources
            if (resourceName) {
              return (
                <ItemResourcesEditor
                  {...props}
                  itemId={itemId}
                  resourceName={resourceName}
                />
              );
            } else {
              return (
                <ItemResourcesPage itemId={itemId} queryScope={queryScope} />
              );
            }
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default ItemPagesContainer;
