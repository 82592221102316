export const defaultUploadFileModalStatus = {
  action: null,
  open: false,
  itemResourceId: null
};

export const defaultFileToUpload = {
  action: null,
  file: null
};

export const defaultError = {
  action: null,
  error: null,
  title: null,
  resourceName: null
};
