// Framework and third-party non-ui
import React, { useEffect } from 'react';

// Hooks, context, and constants
import { useTranslation } from 'react-i18next';
import { useUIContext } from 'contexts/UIContext';
import { useLocation } from 'react-router-dom';

// App components
import ItemDetails from './ItemDetails';
import CopyItems from './CopyItems';

// Component specific modules (Component-styled, etc.)
import {
  StyledItemDrawerHeader,
  StyledItemDrawerBody,
  StyledItemDrawer
} from './ItemDrawer-styled';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import XIcon from 'calcite-ui-icons-react/XIcon';

const ItemDrawer = () => {
  // ----- Language -----
  const { t } = useTranslation();

  // ----- Item Drawer State -----
  const {
    closeItemDrawer,
    itemDrawerState,
    isEnterpriseSignIn
  } = useUIContext();

  // ----- Routing -----
  const { pathname } = useLocation();
  useEffect(() => {
    closeItemDrawer(); // close drawer on route change
  }, [pathname]);
  const searchParams = new URLSearchParams(window.location.search);
  const queryScope = searchParams.get('queryScope');

  const viewConfig = () => {
    switch (itemDrawerState.view) {
      case 'CopyItems':
        return {
          title: t('Item.Copy.CopyingItems', {
            count: itemDrawerState.items.length
          }),
          component: (
            <CopyItems items={itemDrawerState.items} queryScope={queryScope} />
          )
        };
      default:
        return {
          title: t('Item.ItemDetails'),
          component: (
            <ItemDetails
              item={itemDrawerState.items[0]}
              queryScope={queryScope}
            />
          )
        };
    }
  };

  return (
    <StyledItemDrawer
      active={itemDrawerState.isOpen}
      onRequestClose={closeItemDrawer}
      drawerWidth={400}
      right
      style={{ display: isEnterpriseSignIn ? 'none' : 'flex' }}
    >
      <StyledItemDrawerHeader>
        <h1>{viewConfig().title}</h1>
        <Button
          onClick={closeItemDrawer}
          icon={<XIcon size={24} />}
          iconButton
        />
      </StyledItemDrawerHeader>
      {itemDrawerState.isOpen && (
        <StyledItemDrawerBody>{viewConfig().component}</StyledItemDrawerBody>
      )}
    </StyledItemDrawer>
  );
};

export default ItemDrawer;
