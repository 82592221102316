export const customFilterGroupTitles = [
  { id: 'filetype', title: 'File Type' },
  { id: 'modified', title: 'Date Modified' },
  { id: 'created', title: 'Date Created' }
];

export const itemTypesTitles = [
  {
    id: 'images',
    title: 'Images',
    group: 'filetype',
    field: 'type',
    query: [],
    urlField: 'images'
  },
  {
    id: 'text',
    title: 'Text',
    group: 'filetype',
    field: 'type',
    query: [],
    urlField: 'text'
  },
  {
    id: 'data',
    title: 'Data',
    group: 'filetype',
    field: 'type',
    query: [],
    urlField: 'data'
  }
];

export const itemResourceResultsTableColumnConfig = [
  { dataKey: 'name', label: 'Name', flexBasis: 200, id: 'name' },
  { dataKey: 'created', label: 'Created', flexBasis: 200, id: 'created' },
  { dataKey: 'size', label: 'Size', flexBasis: 200, id: 'size' },
  { dataKey: '', label: '', flexBasis: 200, id: 'actions' }
];
