import styled from 'styled-components';

// Assets
import AssistantBackground from '../../assets/assistant-background.png';

const StyledPageContainerWrapper = styled.main`
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  overflow: visible;
  display: flex;
  background-color: ${props =>
    props.darkBackground
      ? 'var(--monaco-dark)'
      : 'var(--calcite-ui-background)'};
  ${props =>
    props.assistantBackground &&
    `
        background: url(${AssistantBackground});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    `}
`;

const StyledPageContainer = styled.div`
  width: var(--content-width);
  max-width: var(--content-max-width);
  height: 100%;
  display: block;
  box-sizing: border-box;
  padding: 1rem 0;
  margin: 0 auto;
  ${props =>
    props.alignCenter &&
    `
        display: flex;
        align-items: center;
    `}
`;

export { StyledPageContainerWrapper, StyledPageContainer };
