// Framework and third-party non-ui
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks, context, and constants

// App components
import PageContainer from '../PageContainer';

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { CalciteH1 } from 'calcite-react/Elements';
import Button from 'calcite-react/Button';
import TextField from 'calcite-react/TextField';
import Loader from 'calcite-react/Loader';
import Form from 'calcite-react/Form';

function SandboxPage() {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('rivers');

  // const [items, itemsPending] = useSelector(ItemsSelector.selectItems);
  const [items, itemsPending] = useState();

  const onSearchInputChanged = e => {
    setSearchTerm(e.target.value);
  };

  const onSearchClicked = () => {};

  return (
    <PageContainer>
      <CalciteH1>{t('Pages.Sandbox.Title')}</CalciteH1>
      <Form onSubmit={e => e.preventDefault()}>
        <TextField
          value={searchTerm}
          label="search term"
          rightAdornment={
            <Button type="submit" onClick={onSearchClicked}>
              {t('Pages.Sandbox.GetItems')}
            </Button>
          }
          onChange={onSearchInputChanged}
        />
      </Form>
      {itemsPending ? <Loader /> : null}
      {items && items.length ? (
        <TextField
          style={{ height: '300px', width: '500px' }}
          type="textarea"
          value={JSON.stringify(items, null, 2)}
          readOnly
        />
      ) : null}
    </PageContainer>
  );
}

export default SandboxPage;
