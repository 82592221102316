// Framework and third-party non-ui
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { getItemResource } from '@esri/arcgis-rest-portal';

// Hooks, context, and constants
import Routes from 'constants/Routes';
import { useItemContext } from 'contexts/ItemContext';

// App components
import PageContainer from 'pages/PageContainer';
import NotFoundPage from 'pages/NotFoundPage';
import JsonTertiaryHeader from 'pages/ItemPages/JsonPage/JsonTertiaryHeader';
import ItemEditor from 'components/ItemEditor';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

// App-specific
import { EditorContextProvider } from '../../JsonPage/EditorContext';
import {
  resourceFileTypes,
  getResourceFileExt,
  fileExtensions
} from '../utils/helper';

const ItemResourcesEditor = ({ itemId, resourceName }) => {
  // ----- Item Context -----
  const {
    setIsContextReady,
    selectedItemResourceEditorText,
    setSelectedItemResourceEditorText,
    clearSelectedItemResourceEditorText,
    loadItemDetails,
    authentication,
    setSelectedItemResource
  } = useItemContext();

  // ----- Routing -----
  const paths = {
    resources: Routes.item.children.resources.path.replace(':itemId', itemId)
  };

  useEffect(() => {
    // ----- Arcgis Online -----
    const getResourceData = async () => {
      setIsContextReady(false);
      let auth = loadItemDetails.portal
        ? {
            ...authentication,
            portal: loadItemDetails.portal
          }
        : { authentication };

      try {
        const itemResourceOptions = {
          ...auth,
          fileName: resourceName
        };

        const itemResource = await getItemResource(itemId, itemResourceOptions);

        setSelectedItemResource(itemResource);

        let itemResourceText = {
          content: null,
          type: ''
        };

        let content = null;
        const resourceFileExt = getResourceFileExt(resourceName);
        switch (resourceFileExt) {
          case fileExtensions.json:
            const itemResourceJSONTxt = await itemResource.text();

            content = JSON.parse(itemResourceJSONTxt);
            break;

          case fileExtensions.xml:
            const parser = new DOMParser();
            const xmlString = await itemResource.text();

            content = parser.parseFromString(xmlString, resourceFileTypes.xml);
            break;

          case fileExtensions.txt:
            content = await itemResource.text();
            break;

          default:
            content = await itemResource.text();
        }

        itemResourceText = {
          content,
          type: resourceFileExt
        };

        setSelectedItemResourceEditorText(itemResourceText);
      } catch (e) {
        console.warn(`Could not retrieve item resource json. ${e}`);
        clearSelectedItemResourceEditorText();
        return;
      }

      setIsContextReady(true);
    };

    if (resourceName) {
      getResourceData();
    }

    return () => {
      clearSelectedItemResourceEditorText();
    };
  }, [authentication, loadItemDetails, itemId, resourceName]);

  // ----- Editor -----

  let language;

  switch (selectedItemResourceEditorText?.type) {
    case fileExtensions.xml:
      language = 'xml';
      break;

    case fileExtensions.txt:
      language = 'plaintext';
      break;

    default:
      language = 'json';
  }

  const editorOptions = {
    language,
    theme: 'dark',
    monacoConstructionOptions: {
      readOnly: true
    },
    value: ''
  };

  return (
    <>
      <EditorContextProvider>
        <JsonTertiaryHeader
          paths={paths}
          itemId={itemId}
          resourceName={resourceName}
        />
        <PageContainer darkBackground>
          <Switch>
            <Route
              path={paths.resources}
              render={props => {
                let windowParams = new URLSearchParams(window.location.search);
                // If current location in browser contains query with key "file", and that query is idential to resourceName, render item resource editor for that resource
                if (windowParams.get('file') === resourceName) {
                  return (
                    <ItemEditor
                      {...props}
                      options={editorOptions}
                      type="itemResources"
                    />
                  );
                }
              }}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </PageContainer>
      </EditorContextProvider>
    </>
  );
};

export default ItemResourcesEditor;
