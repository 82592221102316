import styled from 'styled-components';
import { CalciteH5 } from 'calcite-react/Elements';
import Form, {
  FormControl,
  FormControlLabel,
  FormHelperText
} from 'calcite-react/Form';
import FileUploader from 'calcite-react/FileUploader';
import TextField from 'calcite-react/TextField';
import Alert, { AlertMessage } from 'calcite-react/Alert';

export const StyledForm = styled(Form)``;

export const StyledPrefixInput = styled(TextField)``;

export const StyledFileUploader = styled(FileUploader)``;

export const StyledFormControl = styled(FormControl)``;

export const StyledFormControlLabel = styled(FormControlLabel)``;

export const StyledH5 = styled(CalciteH5)`
  color: ${({ color }) => color && color};
`;

export const StyledFormHelperText = styled(FormHelperText)`
  height: 2rem;
  width: auto;
`;

export const StyledAlert = styled(Alert)``;
export const StyledAlertMessage = styled(AlertMessage)``;
