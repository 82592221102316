// Framework and third-party non-ui
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

// App components
import PageContainer from '../../pages/PageContainer';

// JSON & Styles
import {
  StyledAuthenticationWrapper,
  StyledButtonWrapper,
  StyledSecondButton
} from './RouteAuthentication-styled';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useItemContext } from 'contexts/ItemContext';
import { useUIContext } from 'contexts/UIContext';
import { deployment } from 'config';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';

const RouteAuthentication = ({ redirectSignIn, account, children }) => {
  const { signIn, getAccountSessionStatus } = useAccountsContext();
  const { itemAccessible, isContextReady, loadItemDetails } = useItemContext();
  const { openAccountDrawer, openEnterpriseSignIn } = useUIContext();

  // ----- Language -----
  const { t } = useTranslation();

  // ----- Routing -----
  const params = useParams();
  const history = useHistory();
  if (redirectSignIn) {
    history.push('/');
  }

  // ----- Authentication -----
  const authorized =
    isContextReady &&
    !itemAccessible &&
    history?.location?.pathname?.includes('/item/') &&
    loadItemDetails.itemId === params.itemId
      ? false
      : true;
  const authenticated =
    account?.token && getAccountSessionStatus(account) ? true : false;
  const validAccount = !!account;

  // ----- Account Reachable ----
  const [isReachable, setIsReachable] = useState(true);

  const setReachable = async account => {
    try {
      const user = await account?.session?.getUser(); //access portal directly and view message
      const reachable = user?.id ? true : false;
      setIsReachable(reachable);
    } catch (e) {
      const reachable = false;
      setIsReachable(reachable);
    }
  };
  setReachable(account);

  // ----- Navigation -----

  const signInOnline = () => signIn();

  const noAccount = () => {
    return (
      <PageContainer alignCenter assistantBackground>
        <StyledAuthenticationWrapper>
          <>
            <h1>{t('Account.SignInToViewTitle')}</h1>
            <StyledButtonWrapper>
              {!deployment.offlineMode && (
                <Button extraLarge white onClick={signInOnline}>
                  {t('Account.SignInAGO')}
                </Button>
              )}
              <Button extraLarge clearWhite onClick={openEnterpriseSignIn}>
                {t('Account.SignInEnterprise')}
              </Button>
            </StyledButtonWrapper>
          </>
        </StyledAuthenticationWrapper>
      </PageContainer>
    );
  };

  const notAuthenticated = () => {
    return (
      <PageContainer alignCenter assistantBackground>
        <StyledAuthenticationWrapper>
          <>
            <h1>{t('Account.UserNotAuthenticatedTitle')}</h1>
            <p>{t('Account.UserNotAuthenticatedDesc')}</p>
            <StyledButtonWrapper>
              {!deployment.offlineMode && (
                <Button extraLarge white onClick={signInOnline}>
                  {t('Account.SignInAGO')}
                </Button>
              )}
              <Button extraLarge clearWhite onClick={openEnterpriseSignIn}>
                {t('Account.SignInEnterprise')}
              </Button>
            </StyledButtonWrapper>
          </>
        </StyledAuthenticationWrapper>
      </PageContainer>
    );
  };

  const notAuthorized = () => {
    return (
      <PageContainer alignCenter assistantBackground>
        <StyledAuthenticationWrapper>
          <>
            <h1>{t('Account.UserUnauthorizedTitle')}</h1>
            <p>{t('Account.UserUnauthorizedDesc')}</p>
            <StyledButtonWrapper>
              <Button extraLarge white onClick={openAccountDrawer}>
                {t('Account.SwitchActiveAccount')}
              </Button>
              <Button
                extraLarge
                clearWhite
                as={Link}
                to={{ pathname: '/', state: { queryScope: 'USER' } }}
              >
                {t('Account.GoToMyContent')}
              </Button>
            </StyledButtonWrapper>
          </>
        </StyledAuthenticationWrapper>
      </PageContainer>
    );
  };

  const notReachable = () => {
    return (
      <PageContainer alignCenter assistantBackground>
        <StyledAuthenticationWrapper>
          <>
            <h1>{t('Account.UserNotReachableTitle')}</h1>
            <p>
              {t('Account.UserNotReachableDesc')} {account?.session?.portal}
            </p>
            <StyledButtonWrapper>
              <StyledSecondButton clearWhite onClick={openAccountDrawer}>
                {t('Account.SwitchActiveAccount')}
              </StyledSecondButton>
            </StyledButtonWrapper>
          </>
        </StyledAuthenticationWrapper>
      </PageContainer>
    );
  };

  //Priority: validAccount (account object exists), authenticated (account is signed-in status), reachable (session.getUser() is possible), authorized(cannot get data but account is valid and reachable)
  if (authorized && authenticated && isReachable) {
    return children;
  } else if (!validAccount) {
    return noAccount();
  } else if (!authenticated) {
    return notAuthenticated();
  } else if (!isReachable) {
    return notReachable();
  } else {
    return notAuthorized();
  }
};

export default RouteAuthentication;
