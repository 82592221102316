import styled from 'styled-components';
import Popover from 'calcite-react/Popover';
import MenuTitle from 'calcite-react/Menu/MenuTitle';
import MenuItem from 'calcite-react/Menu/MenuItem';

export const PopoverContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const StyledPopover = styled(Popover)`
  background-color: 1px solid var(--calcite-ui-foreground-1);
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 15%);
`;

export const StyledMenuTitle = styled(MenuTitle)`
  background-color: var(--calcite-ui-foreground-1);
  font-weight: var(--calcite-font-weight-bold);
  border-bottom: 1px solid var(--calcite-ui-border-3);
  margin: 0 0.75rem 0 0.75rem;
  :hover {
    background-color: var(--calcite-ui-foreground-1);
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  border-top: none;
  padding: 0.5rem 1rem 0.5rem 1.5rem;

  [class='calcite-icon'] {
    padding: 0px 5px 3px 5px;
    vertical-align: middle;
  }

  :hover {
    :before {
      content: '\\2022';
      position: absolute;
      left: 1rem;
      font-size: 0.8em;
      color: var(--calcite-ui-background);
    }
  }
`;

export const StyledActiveMenuItem = styled(MenuItem)`
  border-top: none;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  font-weight: var(--calcite-font-weight-bold);

  [class='calcite-icon'] {
    padding: 0px 5px 3px 5px;
    vertical-align: middle;
  }

  :before {
    content: '\\2022';
    position: absolute;
    left: 1rem;
    font-size: 0.8em;
    color: var(--calcite-ui-brand)
  }
`;
