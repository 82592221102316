// Framework and third-party non-ui
import React, { useState } from 'react';

// Hooks, context, and constants
import { useTranslation } from 'react-i18next';

// JSON & Styles
import {
  PopoverContainer,
  StyledPopover,
  StyledMenuTitle,
  StyledMenuItem,
  StyledActiveMenuItem
} from './ViewPopover-styled';

// Third-party components (buttons, icons, etc.)
import Menu from 'calcite-react/Menu/Menu';
import Button from 'calcite-react/Button';
import TableIcon from 'calcite-ui-icons-react/TableIcon';
import GridIcon from 'calcite-ui-icons-react/GridIcon';
import ListIcon from 'calcite-ui-icons-react/ListIcon';
import Question from 'calcite-ui-icons-react/QuestionIcon';

const ViewPopover = ({ currentView, setCurrentView }) => {
  // ----- Language -----
  const { t } = useTranslation();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const closePopover = () => {
    setPopoverOpen(false);
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const toggleView = view => {
    setCurrentView(view);
    closePopover();
  };

  const renderMenuItems = views => {
    let menuItems = [];
    views.forEach(view => {
      if (view === currentView) {
        menuItems.push(
          <StyledActiveMenuItem onClick={() => toggleView(view)}>
            {getViewIcon(view, 16)}
            {view[0].toUpperCase() + view.slice(1)}
          </StyledActiveMenuItem>
        );
      } else {
        menuItems.push(
          <StyledMenuItem onClick={() => toggleView(view)}>
            {getViewIcon(view, 16)}
            {view[0].toUpperCase() + view.slice(1)}
          </StyledMenuItem>
        );
      }
    });
    return menuItems;
  };

  const getViewIcon = (view, size) => {
    switch (view) {
      case 'table':
        return <TableIcon size={size} />;
      case 'grid':
        return <GridIcon size={size} />;
      case 'list':
        return <ListIcon size={size} />;
      default:
        return <Question size={size} />;
    }
  };

  return (
    <PopoverContainer>
      <StyledPopover
        targetEl={
          <Button
            white
            small
            onClick={togglePopover}
            icon={getViewIcon(currentView, 16)}
            iconPosition="before"
          >
            {currentView[0].toUpperCase() + currentView.slice(1)}
          </Button>
        }
        open={popoverOpen}
        onRequestClose={closePopover}
        placement={'bottom-end'}
      >
        <Menu>
          <StyledMenuTitle>{t('Item.View')}</StyledMenuTitle>
          {renderMenuItems(['table', 'grid', 'list'])}
        </Menu>
      </StyledPopover>
    </PopoverContainer>
  );
};

export default ViewPopover;
