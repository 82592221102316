// Framework and third-party non-ui
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Calcite theme and Esri colors
import Button from 'calcite-react/Button';

// Third-party components (buttons, icons, etc.)
import { CalciteA } from 'calcite-react/Elements';
import { CalciteLoader } from '@esri/calcite-components-react';

export const StyledItemResourcesPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledToolbarBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledBtn = styled(Button)``;

export const StyledDownloadBtn = styled(Button)`
  margin-left: 1rem;
`;

export const StyledCalciteLoader = styled(CalciteLoader)`
  margin-left: 1rem;
`;

export const StyledLink = styled(CalciteA)``;

export const StyledRouterLink = styled(Link)`
  /* color: var(--calcite-ui-brand); */
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.linkHover};
    text-decoration: underline;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFilterButton = styled(Button)`
  ${props =>
    props.areFiltersVisible &&
    css`
      background-color: var(--calcite-ui-foreground-current);
      border: 1px solid var(--calcite-ui-foreground-current);
      :hover {
        background-color: var(--calcite-ui-foreground-current);
        border: 1px solid var(--calcite-ui-foreground-current);
      }
    `}
`;
