// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';
import Editor, { loader } from '@monaco-editor/react';

// Hooks, context, and constants
import { useItemContext } from 'contexts/ItemContext';
import { useEditorContext } from '../../pages/ItemPages/JsonPage/EditorContext';

// JSON & Styles
import { StyledEditorContainer } from './ItemEditor-styled';

// App Components
import ItemEditorEmptyState from './ItemEditorEmptyState';
import { homepage } from '../../../package.json';

const ItemEditor = ({
  options: { language, value, theme, monacoConstructionOptions } = {},
  type
}) => {
  // Setup Monaco to run offline (use node modules instead of CDN)
  loader.config({
    paths: {
      vs: `${homepage}/vs`
    }
  });

  const [isReady, setIsReady] = useState(false);

  //** Set Editor Context */
  const { valid, setEditorObject, setEditorValue } = useEditorContext();

  /** Set initial state */
  const {
    isContextReady,
    itemData,
    itemDescription,
    selectedItemResourceEditorText
  } = useItemContext();

  /** Set ready status and editor */
  const handleEditorDidMount = (editor, monaco) => {
    setEditorObject(editor, monaco);
    setIsReady(editor);
  };

  /** Set editor value */
  useEffect(() => {
    if (isContextReady && isReady) {
      let itemJson =
        type === 'itemResources'
          ? selectedItemResourceEditorText
          : type === 'itemDescription'
          ? itemDescription
          : itemData;
      if (type !== 'itemResources') {
        itemJson = {
          content: itemJson,
          type: 'json'
        };
      }

      setEditorValue(itemJson);
    }
  }, [
    isContextReady,
    isReady,
    type,
    selectedItemResourceEditorText,
    itemDescription,
    itemData,
    setEditorValue
  ]);

  return (
    <StyledEditorContainer data-testid="ItemEditor-test">
      <>
        {valid ? (
          <Editor
            data-testid="Editor-test"
            height="100%"
            width="100%"
            onMount={handleEditorDidMount}
            value={value}
            language={language === 'xml' ? 'html' : language}
            theme={theme === 'dark' ? 'vs-dark' : theme}
            options={monacoConstructionOptions}
          />
        ) : (
          <ItemEditorEmptyState type={type} />
        )}
      </>
    </StyledEditorContainer>
  );
};

export default ItemEditor;
