// Framework and third-party non-ui
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }
  #root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // Color Variables
    --assistant-green: rgb(40, 136, 53);
    --monaco-dark: #1e1e1e;
    --monaco-light: #2d2e30;
    // Screen Size Breakpoint Variables
    --screen-size-small: 480px;
    --screen-size-medium: 860px;
    --screen-size-large: 1450px;
    // Content Width Variables
    --content-width: 1450px;
    --content-max-width: 96vw;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--calcite-sans-family);
    background-color: var(--calcite-ui-background);
    @keyframes fadeInAndDown {
      0% {
        opacity: 0;
        transform: translateY(-2px);
      }
    }
    @keyframes fadeInAndRight {
      0% {
        opacity: 0;
        transform: translateX(-2px);
      }
    }
  }
  a {
    color: var(--calcite-ui-text-link);
  }
`;

export default GlobalStyle;
