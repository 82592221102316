// Framework and third-party non-ui
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { deployment } from 'config';

// App components
import AccountDrawer from '../AccountDrawer';
import HeaderSignIn from './HeaderSignIn';

// Third-party components (buttons, icons, etc.)
import TopNav, {
  TopNavActionsList,
  TopNavTitle,
  TopNavList
} from 'calcite-react/TopNav';

import { StyledTopNavLink } from './Header-styled';

function Header() {
  const { selectedAccount } = useAccountsContext();
  const { t } = useTranslation();
  const versionInfo = '20230927.2';

  return (
    <TopNav>
      <TopNavTitle
        as={selectedAccount && Link}
        to={selectedAccount && '/content?queryScope=USER'}
      >
        {t('App.Title')}
      </TopNavTitle>
      <TopNavList>
        <span style={{ fontSize: '0.75rem' }}>{`Beta ${
          process.env.NODE_ENV === 'production' ? `| ${versionInfo}` : ''
        }`}</span>
      </TopNavList>
      <TopNavActionsList>
        {(!deployment.offlineMode || deployment.userGuideUrl) && (
          <StyledTopNavLink
            margin
            href={
              deployment.userGuideUrl || 'https://guide.assistant.esri-ps.com'
            }
            target="_blank"
          >
            {t('App.UserGuide')}
          </StyledTopNavLink>
        )}
        {!deployment.offlineMode && (
          <StyledTopNavLink
            margin
            href="https://github.com/EsriPS/arcgis-assistant-feedback"
            target="_blank"
          >
            {t('App.ProvideFeedback')}
          </StyledTopNavLink>
        )}
        {selectedAccount ? <AccountDrawer /> : <HeaderSignIn />}
      </TopNavActionsList>
    </TopNav>
  );
}

export default Header;
