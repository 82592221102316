// Framework and third-party non-ui
import styled from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';

const StyledAuthenticationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--calcite-ui-foreground-1);
  h1 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: var(--calcite-font-size-4);
    font-weight: var(--calcite-font-weight-normal);
    text-align: center;
    line-height: 1;
  }
  p {
    margin: 0;
    font-size: var(--calcite-font-size-1);
    font-weight: var(--calcite-font-weight-light);
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  gap: 1.5rem;
  @media (max-width: var(--screen-size-medium)) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const StyledSecondButton = styled(Button)`
  margin-left: 0.75rem;
`;

export { StyledButtonWrapper, StyledAuthenticationWrapper, StyledSecondButton };
