import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getBasePath } from 'utilities/urls';

const basename = getBasePath();

// const getLanguageCode = () => i18n.languages[0] || 'en-US';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // have a common namespace used around the full app
    ns: ['strings'],
    defaultNS: 'strings',

    backend: {
      // a custom loadPath is needed when
      // hosted outside the root of the web server
      loadPath: `${basename}/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: {
      default: ['en-US', 'en']
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      wait: true,
      useSuspense: true
    },

    debug: true
  });

export default i18n;
