// Framework and third-party non-ui
import React, { useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { deployment, preferences } from 'config';

// JSON & Styles
import {
  StyledMoreDetails,
  StyledDetails
} from './EnterpriseSignInModal-styled';

// Calcite Components
import {
  CalciteIcon,
  CalciteModal,
  CalciteLabel,
  CalciteInput,
  CalciteButton,
  CalciteInputMessage,
  CalciteTabs,
  CalciteTabNav,
  CalciteTab,
  CalciteTabTitle,
  CalciteNotice,
  CalciteScrim
} from '@esri/calcite-components-react';

const EnterpriseSignIn = () => {
  // Translation
  const { t } = useTranslation();

  // Routing
  const history = useHistory();
  const { pathname } = useLocation();

  // Form Validation
  const [isPending, setIsPending] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [clientIdError, setClientIdError] = useState(false);
  const [webTierError, setWebTierError] = useState(false);
  const [options, setOptions] = useState({
    portalUrl: preferences.defaultPortalUrl
  });

  // Accounts Context
  const {
    signIn,
    storeEnterpriseOptions,
    getEnterpriseOptions
  } = useAccountsContext();
  const enterpriseOptions = getEnterpriseOptions();

  // UI Context
  const {
    closeEnterpriseSignIn,
    isEnterpriseSignIn,
    itemDrawerState
  } = useUIContext();
  const setActive = !itemDrawerState.isOpen;

  // Enterprise Login Option Configs
  const [loginTypes, setLoginTypes] = useState([
    ...(deployment.enableOAuth
      ? [
          {
            ref: 'OAuth',
            active: true,
            open: false, // is description visible
            title: 'Pages.SignIn.OAuth.Title',
            description: 'Pages.SignIn.OAuth.Description'
          }
        ]
      : []),
    // {
    //   ref: 'direct',
    //   active: false,
    //   open: false,
    //   title: 'Pages.SignIn.Direct.Title',
    //   description: 'Pages.SignIn.Direct.Description'
    // },
    ...(deployment.enableWebTier
      ? [
          {
            ref: 'WebTier',
            active: !deployment.enableOAuth,
            open: false,
            title: 'Pages.SignIn.WebTier.Title',
            description: 'Pages.SignIn.WebTier.Description'
          }
        ]
      : [])
  ]);

  const handleURLInput = event => {
    const portalUrl = event.currentTarget.value;
    const { clientId } =
      enterpriseOptions.find(option => option.portalUrl === portalUrl) || {};
    setOptions({
      ...options,
      portalUrl,
      clientId
    });
    document.getElementById('client_id').value = clientId ? clientId : '';

    setUrlError(false);
    if (clientId) setClientIdError(false);
  };

  const handleAppIdInput = event => {
    setOptions({
      ...options,
      clientId: event.currentTarget.value
    });
    setClientIdError(false);
  };

  const handleOnTab = e => {
    setLoginTypes(
      loginTypes.map((type, index) => {
        return { ...type, active: e.detail.tab === index };
      })
    );
  };

  const toggleDesc = () => {
    setLoginTypes(
      loginTypes.map(type => {
        return { ...type, open: type.active ? !type.open : type.open };
      })
    );
  };

  const handleSubmit = useCallback(async () => {
    setIsPending(true);

    // Handle URL field validation
    if (!options?.portalUrl) setUrlError(true);

    const handleSuccess = () => {
      // Route user to the correct page
      if (['/signin', '/authorize'].includes(pathname)) {
        history.push('content');
      }

      // Close the signin modal
      closeEnterpriseSignIn();
    };

    // Handle Different Login Types
    const loginType = loginTypes.find(t => t.active).ref;

    switch (loginType) {
      // OAuth Submit
      case 'OAuth':
        if (!options?.clientId) setClientIdError(true);
        else if (options?.portalUrl) {
          storeEnterpriseOptions({
            portalUrl: options?.portalUrl,
            clientId: options?.clientId
          });
          try {
            await signIn(options, setActive, 'OAuth2');
            handleSuccess();
          } catch (e) {
            setUrlError(true);
          }
        }
        break;

      // PKI Submit
      case 'WebTier':
        setWebTierError(false);
        try {
          await signIn(options, setActive, 'WebTier');
          handleSuccess();
        } catch (e) {
          setWebTierError(true);
        }
        break;
      default:
        break;
    }
    setIsPending(false);
  }, [
    options,
    setClientIdError,
    setUrlError,
    storeEnterpriseOptions,
    signIn,
    setActive,
    loginTypes
  ]);

  return (
    <CalciteModal
      active={isEnterpriseSignIn ? true : undefined}
      onCalciteModalClose={closeEnterpriseSignIn}
      width="s"
    >
      <h2 slot="header" id="modal-title" name={t('Account.SignInEnterprise')}>
        {t('Account.SignInEnterprise')}
      </h2>
      <form slot="content">
        {/* Loading State */}
        {isPending && <CalciteScrim loading />}

        {/* Enterprise URL Field */}
        <CalciteLabel status={clientIdError ? 'invalid' : 'idle'}>
          {t(`Pages.SignIn.SignInEnterpriseLabel`)}
          <CalciteInput
            required
            id="portal_url"
            name="enterprise_portal_url"
            status={urlError ? 'invalid' : 'idle'}
            value={options.portalUrl}
            placeholder={t(`Pages.SignIn.EnterpriseUrlPlaceholder`)}
            onCalciteInputChange={handleURLInput}
          />
          <CalciteInputMessage icon status="invalid" active={urlError}>
            {t('Pages.SignIn.EnterpriseUrlErrorMessage')}
          </CalciteInputMessage>
        </CalciteLabel>

        <CalciteTabs>
          <CalciteTabNav slot="tab-nav" onCalciteTabChange={handleOnTab}>
            {loginTypes.map(type => {
              return (
                <CalciteTabTitle key={type.ref} active={type.active}>
                  {t(type.title)}
                </CalciteTabTitle>
              );
            })}
          </CalciteTabNav>

          {loginTypes.map(type => {
            return (
              <CalciteTab
                key={type.ref}
                style={{ width: '100%', marginTop: '1rem' }}
              >
                {/* App ID Field */}
                {type.ref === 'OAuth' && (
                  <CalciteLabel status={clientIdError ? 'invalid' : 'idle'}>
                    {t(`Pages.SignIn.AppIdLabel`)}
                    <CalciteInput
                      scale="m"
                      id="client_id"
                      name="app_client_id"
                      placeholder={t(`Pages.SignIn.AppIdPlaceholder`)}
                      required
                      status={clientIdError ? 'invalid' : 'idle'}
                      onCalciteInputChange={handleAppIdInput}
                    />
                    <CalciteInputMessage
                      icon
                      status="invalid"
                      active={clientIdError}
                    >
                      {t('Pages.SignIn.AppIdErrorMessage')}
                    </CalciteInputMessage>
                  </CalciteLabel>
                )}

                {/* App ID Field */}
                {type.ref === 'WebTier' && webTierError && (
                  <CalciteNotice
                    active
                    icon
                    color="red"
                    style={{ marginBottom: '1rem' }}
                  >
                    <div slot="title">
                      {t('Pages.SignIn.WebTierErrorTitle')}
                    </div>
                    <div slot="message">
                      {t('Pages.SignIn.WebTierErrorMessage')}
                    </div>
                  </CalciteNotice>
                )}

                {/* More Details */}
                <StyledMoreDetails onClick={toggleDesc}>
                  {t('Pages.SignIn.MoreDetails')}
                  <CalciteIcon
                    style={{ marginLeft: '0.25rem' }}
                    scale="s"
                    icon={type.open ? 'chevron-up' : 'chevron-down'}
                  />
                </StyledMoreDetails>

                {type.open && (
                  <StyledDetails>
                    {/* See https://react.i18next.com/latest/trans-component */}
                    {type.ref === 'OAuth' && (
                      <Trans i18nKey={type.description}>
                        In order to log in to a Portal for ArcGIS instance using
                        a SAML-based Identity Provider, you will need to
                        Register ArcGIS Assistant as an application in your
                        Portal, to generate an AppID that can identify this app
                        as an allowed client of the Portal. To do so, follow the
                        instructions
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://enterprise.arcgis.com/en/portal/latest/use/add-items.htm#ESRI_SECTION1_0D1B620254F745AE84F394289F8AF44B"
                        >
                          here
                        </a>
                        <strong>Other Application</strong>
                        <strong>https://ago-assistant.esri.com/</strong>
                        as the Redirect URI.
                      </Trans>
                    )}
                    {type.ref === 'WebTier' && (
                      <Trans i18nKey={type.description}>
                        This login method is used for Portals with web-tier
                        authentication, using username and password or client
                        certificate (PKI) authentication methods, often referred
                        to as “SSO” or Single Sign On. This authentication
                        method is most often used for internal-facing Portals
                        inside of an intranet.
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://server.arcgis.com/en/portal/latest/administer/windows/use-integrated-windows-authentication-with-your-portal.htm"
                        >
                          More info
                        </a>
                      </Trans>
                    )}
                  </StyledDetails>
                )}
              </CalciteTab>
            );
          })}
        </CalciteTabs>
      </form>

      {/* Submit */}
      <CalciteButton slot="primary" type="submit" onClick={handleSubmit}>
        {t('App.Continue')}
      </CalciteButton>

      {/* Cancel */}
      <CalciteButton
        slot="secondary"
        appearance="outline"
        onClick={closeEnterpriseSignIn}
      >
        {t('App.Cancel')}
      </CalciteButton>
    </CalciteModal>
  );
};

export default EnterpriseSignIn;
