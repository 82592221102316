// Framework and third-party non-ui
import React from 'react';
import { useTranslation } from 'react-i18next';

// Hooks, context, and constants

// App components
import PageContainer from '../PageContainer';

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { CalciteH1 } from 'calcite-react/Elements';

function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <CalciteH1>{t('Pages.NotFound.Title')}</CalciteH1>
    </PageContainer>
  );
}

export default NotFoundPage;
