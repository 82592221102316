import { homepage } from '../../package.json';
import { request } from '@esri/arcgis-rest-request';

export function getBasePath() {
  const basePath = `${window.location.origin}${homepage}`;
  return basePath;
}

export const downloadFile = async ({
  url,
  format,
  token,
  filename,
  onError
}) => {
  try {
    const response = await request(url, {
      params: {
        f: format,
        token
      }
    });

    let anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = (window.webkitURL || window.URL).createObjectURL(response);
    anchor.dataset.downloadurl = [
      response?.type,
      anchor.download,
      anchor.href
    ].join(':');

    anchor.click();
  } catch (error) {
    onError(error);
    console.warn(error);
  }
};
