export const filterGroupTitles = [
  { id: 'itemtype', title: 'Item Type' },
  { id: 'modified', title: 'Date Modified' },
  { id: 'created', title: 'Date Created' }
];

export const itemTypesTitles = [
  {
    id: 'maps',
    title: 'Maps',
    group: 'itemtype',
    field: 'type',
    query: [
      'Project Package',
      'Windows Mobile Package',
      'Map Package',
      'Basemap Package',
      'Mobile Basemap Package',
      'Mobile Map Package',
      'Pro Map',
      'Project Package',
      'Web Map',
      'CityEngine Web Scene',
      'Map Document',
      'Globe Document',
      'Scene Document',
      'Published Map',
      'Explorer Map',
      'ArcPad Package',
      'Map Template'
    ],
    urlField: 'maps'
  },
  {
    id: 'layers',
    title: 'Layers',
    group: 'itemtype',
    field: 'type',
    query: [
      'Scene Service',
      'Feature Collection',
      'Route Layer',
      'Layer',
      'Explorer Layer',
      'Tile Package',
      'Compact Tile Package',
      'Vector Tile Package',
      'Scene Package',
      'Layer Package',
      'Feature Service',
      'Stream Service',
      'Feed',
      'Map Service',
      'Vector Tile Service',
      'Image Service',
      'WMS',
      'WFS',
      'WMTS',
      'KML',
      'Globe Service',
      'CSV',
      'Shapefile',
      'GeoJson',
      'Service Definition',
      'File Geodatabase',
      'CAD Drawing',
      'Relational Database Connection'
    ],
    urlField: 'layers'
  },
  {
    id: 'scenes',
    title: 'Scenes',
    group: 'itemtype',
    field: 'type',
    query: [],
    urlField: 'scenes'
  },
  {
    id: 'apps',
    title: 'Apps',
    group: 'itemtype',
    field: 'type',
    query: [
      'Code Sample',
      'Web Mapping Application',
      'Mobile Application',
      'Application',
      'Desktop Application Template',
      'Desktop Application',
      'Operation View',
      'Dashboard',
      'Operations Dashboard Extension',
      'Workforce Project',
      'Insights Workbook',
      'Insights Page',
      'Insights Model',
      'Hub Page',
      'Hub Initiative',
      'Hub Site Application',
      'StoryMap',
      'Web Experience',
      'Web Experience Template',
      'Form'
    ],
    urlField: 'applications'
  },
  {
    id: 'tools',
    title: 'Tools',
    group: 'itemtype',
    field: 'type',
    query: [
      'Raster function template',
      'Geodata Service',
      'Workflow Manager Package',
      'Rule Package',
      'Operations Dashboard Add In',
      'Workflow Manager Service',
      'ArcGIS Pro Configuration'
    ],
    urlField: 'tools'
  },
  {
    id: 'files',
    title: 'Files',
    group: 'itemtype',
    field: 'type',
    query: [
      'Image',
      'Layout',
      'Desktop Style',
      'Project Template',
      'Report Template',
      'Pro Report',
      'Statistical Data Collection',
      '360 VR Experience',
      'netCDF',
      'PDF',
      'CSV',
      'Administrative Report'
    ],
    urlField: 'files'
  },
  {
    id: 'notebooks',
    title: 'Notebooks',
    group: 'itemtype',
    field: 'type',
    query: ['Notebook'],
    urlField: 'notebooks'
  }
];

export const dateModifiedTitles = [
  {
    id: 'today-modified',
    title: 'Today',
    field: 'modified',
    group: 'modified'
  },
  {
    id: 'yesterday-modified',
    title: 'Yesterday',
    field: 'modified',
    group: 'modified'
  },
  {
    id: 'last7Days-modified',
    title: 'Last 7 Days',
    field: 'modified',
    group: 'modified'
  },
  {
    id: 'last30Days-modified',
    title: 'Last 30 Days',
    field: 'modified',
    group: 'modified'
  },
  {
    id: 'custom-modified',
    title: 'Custom Range...',
    field: 'modified',
    group: 'modified',
    startDate: null,
    endDate: null
  }
];

export const dateCreatedTitles = [
  {
    id: 'today-created',
    title: 'Today',
    field: 'uploaded',
    group: 'created'
  },
  {
    id: 'yesterday-created',
    title: 'Yesterday',
    field: 'uploaded',
    group: 'created'
  },
  {
    id: 'last7Days-created',
    title: 'Last 7 Days',
    field: 'uploaded',
    group: 'created'
  },
  {
    id: 'last30Days-created',
    title: 'Last 30 Days',
    field: 'uploaded',
    group: 'created'
  },
  {
    id: 'custom-created',
    title: 'Custom Range...',
    field: 'uploaded',
    group: 'created',
    startDate: null,
    endDate: null
  }
];

export const filterItemTitles = [
  ...itemTypesTitles,
  ...dateModifiedTitles,
  ...dateCreatedTitles
];

export const mapsFilterItemOptionTitles = [
  {
    id: 'webmaps',
    title: 'Web Maps',
    group: 'maps',
    field: 'type',
    query: ['Web Map', 'CityEngine Web Scene'],
    urlField: 'webmaps'
  },
  {
    id: 'mapfiles',
    title: 'Map Files',
    group: 'maps',
    field: 'type',
    query: [
      'Map Document',
      'Windows Mobile Package',
      'Globe Document',
      'Scene Document',
      'Published Map',
      'Explorer Map',
      'ArcPad Package',
      'Map Package',
      'Basemap Package',
      'Mobile Basemap Package',
      'Mobile Map Package',
      'Pro Map',
      'Project Package',
      'Map Template'
    ],
    urlField: 'mapfiles'
  }
];

export const layersFilterItemOptionTitles = [
  {
    id: 'featurelayers',
    title: 'Feature Layers',
    group: 'layers',
    field: 'type',
    query: ['Feature Collection', 'Feature Service', 'Stream Service', 'WFS'],
    urlField: 'weblayers-features'
  },
  {
    id: 'tilelayers',
    title: 'Tile Layers',
    group: 'layers',
    field: 'type',
    query: ['WMTS', 'Map Service', 'Vector Tile Service'],
    urlField: 'weblayers-tiles'
  },
  {
    id: 'mapimagelayers',
    title: 'Map Image Layers',
    group: 'layers',
    field: 'type',
    query: ['Map Service', 'WMS'],
    urlField: 'weblayers-mapimage'
  },
  {
    id: 'imagerylayers',
    title: 'Imagery Layers',
    group: 'layers',
    field: 'type',
    query: ['Image Service'],
    urlField: 'weblayers-imagery'
  },
  {
    id: 'scenelayers',
    title: 'Scene Layers',
    group: 'layers',
    field: 'type',
    query: ['Scene Service'],
    urlField: 'weblayers-scenelayers'
  },
  {
    id: 'tables',
    title: 'Tables',
    group: 'layers',
    field: 'type',
    query: ['Table'],
    urlField: 'weblayers-tables'
  },
  {
    id: 'layerfiles',
    title: 'Layer Files',
    group: 'layers',
    field: 'type',
    query: [
      'Layer',
      'Explorer Layer',
      'Tile Package',
      'Compact Tile Package',
      'Vector Tile Package',
      'Scene Package',
      'Layer Package',
      'CSV',
      'Shapefile',
      'GeoJson',
      'Service Definition',
      'File Geodatabase',
      'CAD Drawing',
      'Microsoft Excel'
    ],
    urlField: 'layerfiles'
  }
];

export const appsFilterItemOptionTitles = [
  {
    id: 'webapps',
    title: 'Web Apps',
    group: 'apps',
    field: 'type',
    query: ['Web Mapping Application'],
    urlField: 'web'
  },
  {
    id: 'dashboards',
    title: 'Dashboards',
    group: 'apps',
    field: 'type',
    query: ['Dashboard'],
    urlField: 'dashboard'
  },
  {
    id: 'sites',
    title: 'Sites',
    group: 'apps',
    field: 'type',
    query: [],
    urlField: 'site'
  },
  { id: 'sites', title: 'Sites', group: 'apps', field: 'type', query: [] },
  {
    id: 'pages',
    title: 'Pages',
    group: 'apps',
    field: 'type',
    query: ['Hub Page', 'Site Page']
  },
  {
    id: 'storymaps',
    title: 'StoryMaps',
    group: 'apps',
    field: 'type',
    query: ['StoryMap'],
    urlField: 'storymap'
  },
  {
    id: 'experiencebuilderapps',
    title: 'Experience Builder Apps',
    group: 'apps',
    field: 'type',
    query: ['Web Experience', 'Web Experience Template'],
    urlField: 'experiencebuilder'
  },
  {
    id: 'webappbuilderapps',
    title: 'Web AppBuilder Apps',
    group: 'apps',
    field: 'type',
    query: [],
    urlField: 'webappbuilder'
  },
  {
    id: 'mobileapps',
    title: 'Mobile Apps',
    group: 'apps',
    field: 'type',
    query: ['Mobile Application'],
    urlField: 'mobile'
  },
  {
    id: 'desktopapps',
    title: 'Desktop Apps',
    group: 'apps',
    field: 'type',
    query: [],
    urlField: 'desktop'
  }
];

export const toolsFilterItemOptionTitles = [
  {
    id: 'locators',
    title: 'Locators',
    group: 'tools',
    field: 'type',
    query: ['Geocoding Service', 'Locator Package'],
    urlField: 'locators'
  },
  {
    id: 'geodatabaseaccess',
    title: 'Geodatabase Access',
    group: 'tools',
    field: 'type',
    query: ['Geodata Service'],
    urlField: 'geodatabase'
  },
  {
    id: 'geometricoperations',
    title: 'Geometric Operations',
    group: 'tools',
    field: 'type',
    query: ['Geometry Service'],
    urlField: 'geometric'
  },
  {
    id: 'geoprocessingtasks',
    title: 'Geoprocessing Tasks',
    group: 'tools',
    field: 'type',
    query: [
      'Geoprocessing Service',
      'Geoprocessing Package',
      'Geoprocessing Sample'
    ],
    urlField: 'geoprocessing'
  },
  {
    id: 'networkanalysis',
    title: 'Network Analysis',
    group: 'tools',
    field: 'type',
    query: ['Network Analysis Service'],
    urlField: 'network'
  }
];

export const filesFilterItemOptionTitles = [
  {
    id: 'documents',
    title: 'Documents',
    group: 'files',
    field: 'type',
    query: [],
    urlField: 'document'
  },
  {
    id: 'images',
    title: 'Images',
    group: 'files',
    field: 'type',
    query: ['Image', '360 VR Experience'],
    urlField: 'image'
  },
  {
    id: 'pdfs',
    title: 'PDFs',
    group: 'files',
    field: 'type',
    query: [],
    urlField: 'pdf'
  },
  { id: 'pdfs', title: 'PDFs', group: 'files', field: 'type', query: ['PDF'] },
  { id: 'csv', title: 'CSVs', group: 'files', field: 'type', query: ['CSV'] },
  {
    id: 'administrativeReports',
    title: 'Administrative Reports',
    group: 'files',
    field: 'type',
    query: ['Administrative Report']
  }
];

export const filterOptionItemTitles = [
  ...mapsFilterItemOptionTitles,
  ...layersFilterItemOptionTitles,
  ...appsFilterItemOptionTitles,
  ...toolsFilterItemOptionTitles,
  ...filesFilterItemOptionTitles
];

export const specialLayerTypes = [
  'tables',
  'featurelayers',
  'tilelayers',
  'mapimagelayers'
];
