// Framework and third-party non-ui
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// App components
import PageContainer from '../PageContainer';

// JSON & Styles
import {
  StyledSignInPage,
  StyledIntro,
  StyledDivider,
  StyledButtonContainer,
  StyledDescriptionItem,
  StyledDescriptionContainer
} from './SignInPage-styled';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { deployment } from 'config';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';

const SignInPage = props => {
  // ----- Language -----
  const { t } = useTranslation();

  // ----- Navigation -----
  const history = useHistory();
  const labels = ['Inspect', 'Update', 'Copy'];
  const { openEnterpriseSignIn } = useUIContext();

  // ----- Accounts -----
  const {
    active,
    signIn,
    selectedAccount,
    getContextStatus
  } = useAccountsContext();
  const contextStatus = getContextStatus();
  const redirectSignIn =
    contextStatus === 'signOut' || contextStatus === 'remove';

  const signInOnline = () => {
    signIn();
  };

  // ----- Routing -----
  useEffect(() => {
    if (selectedAccount && !redirectSignIn) {
      history.push('content');
    }
  }, [active, selectedAccount, history, redirectSignIn]);

  return (
    <PageContainer assistantBackground alignCenter>
      <StyledSignInPage>
        <StyledIntro>
          <h1>{t('App.Title')}</h1>

          <StyledDivider />
          <StyledDescriptionContainer>
            {labels.map(label => {
              return (
                <StyledDescriptionItem key={label}>
                  <label>{t(`Pages.SignIn.${label}.Label`)}</label>
                  <p>{t(`Pages.SignIn.${label}.Description`)}</p>
                </StyledDescriptionItem>
              );
            })}
          </StyledDescriptionContainer>
          <StyledButtonContainer>
            {!deployment.offlineMode && (
              <Button extraLarge white onClick={signInOnline}>
                {t('Account.SignInAGO')}
              </Button>
            )}
            <Button
              extraLarge
              clearWhite={!deployment.offlineMode}
              white={deployment.offlineMode}
              onClick={openEnterpriseSignIn}
            >
              {t('Account.SignInEnterprise')}
            </Button>
          </StyledButtonContainer>
        </StyledIntro>
      </StyledSignInPage>
    </PageContainer>
  );
};

export default SignInPage;
