// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
  Prompt
} from 'react-router-dom';
import isEqual from 'lodash/isEqual';

// App components

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useItemContext } from 'contexts/ItemContext';
import { useEditorContext } from '../EditorContext';

// JSON & Styles
import {
  StyledEditorHeaderWrapper,
  StyledEditorHeader,
  StyledEditorPrimaryActionsWrapper,
  StyledSaveCancelWrapper,
  StyledButtonWrapper,
  StyledCancelButton,
  StyledTerNavItem,
  StyledWarningModal,
  StyledTooltip
} from './EditorTertiaryHeader-styled';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import { notify } from 'calcite-react/Toaster';
import Breadcrumbs, { Crumb } from 'calcite-react/Breadcrumbs';
import CheckCircleIcon from 'calcite-ui-icons-react/CheckCircleIcon';
import XCircleIcon from 'calcite-ui-icons-react/XCircleIcon';
import ExclamationMarkTriangleIcon from 'calcite-ui-icons-react/ExclamationMarkTriangleIcon';
import BracketsCurlyIcon from 'calcite-ui-icons-react/BracketsCurlyIcon';
import { fileExtensions } from 'pages/ItemPages/ItemResourcesPage/utils/helper';

const EditorTertiaryHeader = ({ paths, itemId, resourceName }) => {
  // -----  Language -----
  const { t } = useTranslation();
  const langRef = 'Pages.Item.SubPages.Json';

  // -----  Routing -----
  const { pathname } = useLocation();
  const [nextLocation, setNextLocation] = useState(null);

  // -----  Account -----
  const { selectedAccount } = useAccountsContext();

  // ----- Editing state of JSON -----
  const {
    saveItemEdits,
    saveItemResourceEdits,
    itemData,
    itemDescription,
    itemEditable,
    isContextReady,
    selectedItemResourceEditorText
  } = useItemContext();
  const {
    enableEdits,
    disableEdits,
    formatDocument,
    hasChanged,
    hasErrors,
    markers,
    getEditorValue,
    setEditorValue
  } = useEditorContext();

  const isOnDataTab = useRouteMatch(paths.data);

  let tabType = 'description';

  if (selectedItemResourceEditorText?.content) {
    tabType = 'resource';
  } else if (!!isOnDataTab) {
    tabType = 'data';
  }

  const [editingJson, setEditingJson] = useState(false);
  const startEditingJson = () => {
    enableEdits();
    setEditingJson(true);
  };
  let itemValue;

  switch (tabType) {
    case 'resource':
      itemValue = selectedItemResourceEditorText?.content;
      break;

    case 'description':
      itemValue = itemDescription;
      break;

    default:
      itemValue = itemData;
      break;
  }

  const errors = markers?.error?.length > 0;
  const warnings = markers?.warning?.length > 0;

  // ----- Tertiary Navigation -----
  const history = useHistory();
  const [editor, setEditor] = useState(false);
  const [warningNavigate, setWarningNavigate] = useState(false);
  const [warningCancelEdit, setWarningCancelEdit] = useState(false);

  // ----- Editing Status Change -----
  const handleNavigation = route => {
    if (editingJson) {
      const editorValue = getEditorValue();

      try {
        if (!isEqual(JSON.parse(editorValue), itemValue)) {
          setEditor(route);
          setWarningNavigate(true);
        } else {
          setEditingJson(false);
          disableEdits();
          history.push(route.replace(':itemId', itemId));
        }
      } catch (e) {
        setEditor(route);
        setWarningNavigate(true);
      }
    } else {
      history.push(route.replace(':itemId', itemId));
    }
  };

  const handleBlockedNavigation = nextLocation => {
    if (hasChanged) {
      // Block if edits are unsaved, then delegate navigation to warning nav modal
      setWarningNavigate({
        path: nextLocation?.pathname + nextLocation?.search
      });
      return false;
    }
    // Do not block
    return true;
  };

  const handleNavWarning = confirm => {
    if (confirm) {
      setEditingJson(false);
      disableEdits();

      setNextLocation(
        warningNavigate?.path || editor.replace(':itemId', itemId)
      );
    }
    setWarningNavigate(false);
  };

  useEffect(() => {
    if (nextLocation) {
      history.push(nextLocation);
    }
  }, [nextLocation, history]);

  const handleCancelEdit = () => {
    try {
      // Check if any editor changes have been made. If not, do not show warning.
      if (hasChanged) {
        setWarningCancelEdit(true);
      } else {
        disableEdits();
        setEditingJson(false);
      }
    } catch (e) {
      setWarningCancelEdit(true);
    }
  };

  const handleCancelEditWarning = confirm => {
    if (confirm) {
      setEditingJson(false);
      disableEdits();

      switch (tabType) {
        case 'resource':
          setEditorValue(selectedItemResourceEditorText);
          break;

        case 'description':
          setEditorValue({
            content: itemDescription,
            type: fileExtensions.json
          });
          break;

        case 'data':
          setEditorValue({
            content: itemData,
            type: fileExtensions.json
          });
          break;

        default:
          break;
      }
    }
    setWarningCancelEdit(false);
  };

  const handleSave = async () => {
    const editorValue = getEditorValue();

    const toastOptions = {
      type: 'success',
      position: 'bottom-right',
      autoClose: 2000
    };

    try {
      // return to read-only mode after save
      if (resourceName) {
        await saveItemResourceEdits({
          authentication: selectedAccount?.session,
          id: itemId,
          resourceName,
          type: selectedItemResourceEditorText.type,
          editorValue
        });
      } else {
        await saveItemEdits({
          authentication: selectedAccount?.session,
          id: itemId,
          type: tabType,
          jsonString: editorValue
        });
      }

      notify(t(`${langRef}.SaveSuccess`), toastOptions);
      setEditingJson(false);
      disableEdits();
    } catch (e) {
      notify(
        t(`${langRef}.SaveError`) +
          (e?.message || t(`${langRef}.UnknownError`)),
        {
          ...toastOptions,
          type: 'error',
          autoClose: 4000
        }
      );
    }
  };

  return (
    <StyledEditorHeaderWrapper>
      <Prompt when={editingJson} message={handleBlockedNavigation} />
      <StyledEditorHeader>
        <StyledWarningModal
          open={warningNavigate}
          onRequestClose={() => {
            setWarningNavigate(false);
          }}
          appElement={document.body}
          title={t(`${langRef}.WarningTitle`)}
          overlayStyle={{ zIndex: '1003' }}
          actions={[
            <Button
              key="continue"
              onClick={() => {
                handleNavWarning(true);
              }}
            >
              {t('App.Continue')}
            </Button>,
            <Button
              key="cancel"
              onClick={() => {
                handleNavWarning(false);
              }}
              halo
            >
              {t('App.Cancel')}
            </Button>
          ]}
        >
          {t(`${langRef}.WarningMessage`)}
        </StyledWarningModal>
        <StyledWarningModal
          open={warningCancelEdit}
          onRequestClose={() => {
            setWarningCancelEdit(false);
          }}
          appElement={document.body}
          title={t(`${langRef}.WarningTitle`)}
          actions={[
            <Button
              key="continue"
              onClick={() => {
                handleCancelEditWarning(true);
              }}
            >
              {t('App.Continue')}
            </Button>,
            <Button
              key="cancel"
              onClick={() => {
                handleCancelEditWarning(false);
              }}
              halo
            >
              {t('App.Cancel')}
            </Button>
          ]}
        >
          {t(`${langRef}.WarningMessage`)}
        </StyledWarningModal>
        <StyledEditorPrimaryActionsWrapper>
          {/* ----- Tertiary Navigation ----- */}
          {resourceName ? (
            <Breadcrumbs dividerCharacter="›" white>
              {isContextReady && (
                <>
                  <Crumb
                    style={{ animation: 'fadeInAndRight 0.2s' }}
                    as={Link}
                    to={paths.resources}
                    hasLink
                  >
                    {t('Pages.Item.SubPages.Resources.Title')}
                  </Crumb>
                  <Crumb style={{ animation: 'fadeInAndRight 0.2s' }}>
                    {resourceName}
                  </Crumb>
                </>
              )}
            </Breadcrumbs>
          ) : (
            <StyledButtonWrapper>
              <StyledTerNavItem
                onClick={() => {
                  handleNavigation(paths.description); //:itemid
                }}
                active={pathname.includes(paths.description)}
              >
                {t(`${langRef}.Description`)}
              </StyledTerNavItem>
              <StyledTerNavItem
                onClick={() => {
                  handleNavigation(paths.data);
                }}
                active={pathname.includes(paths.data)}
              >
                {t(`${langRef}.Data`)}
              </StyledTerNavItem>
            </StyledButtonWrapper>
          )}

          {/* ----- Json Editor Actions ----- */}
          {editingJson && (
            <StyledButtonWrapper>
              {errors && (
                <StyledTooltip title={t(`${langRef}.MarkerErrorNote`)}>
                  <Button
                    transparent
                    disabled
                    style={{ opacity: 0.4 }}
                    iconPosition="before"
                    iconButton
                    icon={
                      <>
                        <XCircleIcon
                          size={16}
                          style={{ paddingRight: '5px' }}
                        />
                        {markers?.error?.length}
                      </>
                    }
                  />
                </StyledTooltip>
              )}
              {warnings && (
                <StyledTooltip title={t(`${langRef}.MarkerWarningNote`)}>
                  <Button
                    transparent
                    disabled
                    style={{ opacity: 0.4 }}
                    iconPosition="before"
                    iconButton
                    icon={
                      <>
                        <ExclamationMarkTriangleIcon
                          size={16}
                          style={{ paddingRight: '5px' }}
                        />
                        {markers?.warning?.length}
                      </>
                    }
                  />
                </StyledTooltip>
              )}

              {!warnings && !errors && (
                <Button
                  transparent
                  disabled
                  style={{ opacity: 0.4 }}
                  iconPosition="before"
                  iconButton
                  icon={<CheckCircleIcon size={16} />}
                />
              )}
              <Button
                transparent
                iconPosition="before"
                icon={<BracketsCurlyIcon size={16} />}
                onClick={() => {
                  formatDocument();
                }}
              >
                {t(`${langRef}.FormatJson`)}
              </Button>
            </StyledButtonWrapper>
          )}
        </StyledEditorPrimaryActionsWrapper>

        {/* ----- Edit, Cancel/Save toggle ----- */}
        {itemValue && (
          <StyledSaveCancelWrapper editingJson={editingJson}>
            {editingJson ? (
              <>
                <StyledCancelButton onClick={handleCancelEdit} clearWhite>
                  {t('App.Cancel')}
                </StyledCancelButton>
                <Button
                  white
                  type="submit"
                  disabled={!hasChanged || hasErrors}
                  onClick={async () => {
                    await handleSave();
                  }}
                >
                  {t('App.Save')}
                </Button>
              </>
            ) : itemEditable ? (
              <Button white onClick={startEditingJson}>
                {resourceName
                  ? t(`${langRef}.EditResource`)
                  : t(`${langRef}.EditJson`)}
              </Button>
            ) : (
              <StyledTooltip
                title={t(`${langRef}.EditRestricted`)}
                placement="bottom-end"
              >
                <Button
                  white
                  onClick={startEditingJson}
                  disabled={!itemEditable}
                >
                  {resourceName
                    ? t(`${langRef}.EditResource`)
                    : t(`${langRef}.EditJson`)}
                </Button>
              </StyledTooltip>
            )}
          </StyledSaveCancelWrapper>
        )}
      </StyledEditorHeader>
    </StyledEditorHeaderWrapper>
  );
};

export default EditorTertiaryHeader;
