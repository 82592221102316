// Framework and third-party non-ui
import styled, { css } from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import { CalciteA } from 'calcite-react/Elements';
import Modal from 'calcite-react/Modal';
import Tooltip from 'calcite-react/Tooltip';

const StyledEditorHeaderWrapper = styled.header`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  background-color: var(--monaco-light);
`;

const StyledEditorHeader = styled.div`
   width: var(--content-width);
  max-width: var(--content-max-width);
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const StyledEditorPrimaryActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
`;

const StyledSaveCancelWrapper = styled.div`
  height: calc(100% - 1rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0.25rem 1rem;
  ${props =>
    props.editingJson &&
    css`
      padding-left: 2rem;
      border-left: 1px solid var(--calcite-ui-border-1);
    `}
`;

const StyledButtonWrapper = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  button {
    color: var(--calcite-ui-text-inverse);
    &:hover {
      color: var(--calcite-ui-text-inverse);
      opacity: 0.8;

    }
  }
`;

const StyledCancelButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledTerNavItem = styled(CalciteA)`
  padding: 0rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--calcite-ui-text-inverse);
  &:hover {
    color: var(--calcite-ui-text-inverse);
      opacity: 0.8;

  }
  ${props =>
    props.active &&
    css`
      background-color: var(--monaco-dark);
    `}
`;

const StyledWarningModal = styled(Modal)``;

const StyledTooltip = styled(Tooltip)``;

export {
  StyledEditorHeaderWrapper,
  StyledEditorHeader,
  StyledEditorPrimaryActionsWrapper,
  StyledSaveCancelWrapper,
  StyledButtonWrapper,
  StyledCancelButton,
  StyledTerNavItem,
  StyledWarningModal,
  StyledTooltip
};
