export const addItem = async ({
  itemId,
  uploadFileModalStatus,
  fileToUpload,
  handleReplaceItemResource,
  handleGetItemResources,
  handleAddItemResource,
  setItemResourceModalError,
  closeModal,
  fileToUploadPrefix
}) => {
  let itemResourceActionResult = null;
  // If uploadFileModalStatus already has itemResourceId associated with it, replace item resource with fileToUpload.  Otherwise, simply add new file as a new item resource to item with specified id
  if (uploadFileModalStatus.itemResourceId) {
    itemResourceActionResult = await handleReplaceItemResource({
      file: fileToUpload.file,
      id: itemId,
      name: uploadFileModalStatus.itemResourceId
    });
  } else {
    itemResourceActionResult = await handleAddItemResource({
      file: fileToUpload.file,
      id: itemId,
      fileToUploadPrefix
    });
  }

  if (itemResourceActionResult?.success) {
    handleGetItemResources({ id: itemId });
    closeModal();
  } else if (itemResourceActionResult) {
    setItemResourceModalError({
      action: 'add',
      error: itemResourceActionResult,
      title: 'Upload File',
      resourceName: null
    });
  } else {
    const error =
      'An upload error occurred.  Please check file type and try again.';

    setItemResourceModalError({
      action: 'add',
      error,
      title: 'Upload File',
      resourceName: null
    });
  }
};

// Deletes resource of given name from item with given id
export const deleteItemResource = async ({
  itemResourcesResults,
  itemResourceModalError,
  handleGetItemResources,
  handleDeleteItemResource,
  setItemResourceModalError,
  closeModal,
  resourceName
}) => {
  const removeItemResult = await handleDeleteItemResource({
    id: itemResourcesResults.id,
    resourceName: resourceName
      ? resourceName
      : itemResourceModalError.resourceName
  });

  if (removeItemResult?.success) {
    handleGetItemResources({ id: itemResourcesResults?.id });
    closeModal();
  } else if (removeItemResult) {
    setItemResourceModalError({
      action: 'delete',
      error: removeItemResult,
      title: 'Delete Resource',
      resourceName: null
    });
  } else {
    const error = 'An error occurred.  Please check file type and try again.';
    setItemResourceModalError({
      action: 'delete',
      error,
      title: 'Delete Resource',
      resourceName: null
    });
  }
};
