import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef
} from 'react';
import { fileExtensions } from '../ItemResourcesPage/utils/helper';

export const EditorContext = createContext();

export const EditorContextProvider = props => {
  // ----- Editor -----
  const [editor, setEditor] = useState(false);
  const [monaco, setMonaco] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [markers, setMarkers] = useState({ error: [], warning: [] });
  const [valid, setValid] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const initialValueRef = useRef(null);
  const valueRef = useRef(null);
  const onChangeRef = useRef({});
  const onValidateRef = useRef({});

  /** Set Editor Object */
  const setEditorObject = useCallback((editor, monaco) => {
    setEditor(editor);
    setMonaco(monaco);
    setIsReady(true);
    if (editor?.onDidChangeModelContent) {
      /** register an onChange listener event */
      const onChangeListener = onChangeRef.current;
      if (onChangeListener?.dispose) onChangeListener.dispose();
      onChangeRef.current = editor.onDidChangeModelContent(event => {
        const editorValue = editor.getValue();
        if (valueRef.current !== editorValue) {
          // onChange(editorValue, event);
          valueRef.current = editorValue;
        }
        if (valueRef.current !== initialValueRef.current) setHasChanged(true);
        else setHasChanged(false);
        // setHasErrors(!(markers.error.length + markers.warning.length));
      });

      /** register an onValidate listener event */
      const onValidateListener = onValidateRef.current;
      if (onValidateListener?.dispose) onValidateListener.dispose();
      onValidateRef.current = monaco.editor.onDidChangeMarkers(uris => {
        const editorUri = editor.getModel()?.uri;
        if (editorUri) {
          const currentEditorHasMarkerChanges = uris.find(
            uri => uri.path === editorUri.path
          );
          if (currentEditorHasMarkerChanges) {
            const markers = monaco.editor.getModelMarkers({
              resource: editorUri
            });
            const error = [],
              warning = [],
              info = [],
              hint = [];
            markers.forEach(marker => {
              switch (marker.severity) {
                case 1: // Hint
                  hint.push(marker);
                  break;
                case 2: // Info
                  info.push(marker);
                  break;
                case 4: // Warning
                  warning.push(marker);
                  break;
                case 8: // Error
                  error.push(marker);
                  break;
                default:
                  break;
              }
            });

            setMarkers({ error, warning, info, hint });
            setHasErrors(!!(error.length + warning.length));
          }
        }
      });
    }
  }, []);

  /** Update Editor Formatting  */
  const formatDocument = useCallback(() => {
    const autoFormat = async () => {
      try {
        const action = await editor.getAction('editor.action.formatDocument');

        const supported = action.isSupported();
        if (supported) {
          await action.run();
        } else {
          throw new Error(
            'Action formatDocument not supported. This may be due to a timing issue.'
          );
        }
      } catch (e) {
        console.error(`Error formatting editor content. ${e}`);
      }
    };
    autoFormat();
  }, [editor]);

  /** Update Editor Focus Position  */
  const jumpTo = useCallback(
    itemId => {
      try {
        const modal = editor.getModel();
        const matches = modal.findMatches(itemId);
        const { startLineNumber, startColumn } =
          (matches && matches[0]?.range) || {};
        editor.revealPositionInCenter({
          lineNumber: startLineNumber,
          column: startColumn
        });
      } catch (e) {
        console.error(`Error revealing itemId: ${itemId}. ${e}`);
      }
    },
    [editor]
  );

  /** Update Editor readOnly  */
  const enableEdits = useCallback(() => {
    try {
      editor.updateOptions({ readOnly: false });
      initialValueRef.current = valueRef.current;
      setHasChanged(false);
    } catch (e) {
      console.error(`Error enabling edits. ${e}`);
    }
  }, [editor]);

  /** Update Editor readOnly  */
  const disableEdits = useCallback(() => {
    try {
      editor.updateOptions({ readOnly: true });
    } catch (e) {
      console.error(`Error enabling edits. ${e}`);
    }
  }, [editor]);

  /** Get current editor text  */
  const getEditorValue = useCallback(() => {
    return valueRef.current;
  }, [valueRef]);

  const setEditorValue = useCallback(
    async editorValue => {
      let textValue, language;
      switch (editorValue.type) {
        case fileExtensions.xml:
          const s = new XMLSerializer();
          textValue = s.serializeToString(editorValue?.content);
          language = 'html'; // using this instead of 'xml' to give us Monaco formattting/syntax support
          break;

        case fileExtensions.txt:
          textValue = editorValue?.content;
          language = 'plaintext';
          break;

        case fileExtensions.json:
          textValue = JSON.stringify(editorValue?.content, null, 4);
          language = 'json';
          break;

        default:
          setValid(false);
          return;
      }
      if (monaco && editor && typeof textValue !== 'undefined') {
        editor.setValue(textValue);
        initialValueRef.current = textValue;
        valueRef.current = textValue;
        setHasChanged(false);
        const model = editor.getModel();
        setValid(true);
        if (model) {
          monaco.editor.setModelLanguage(model, language);
        }
      } else {
        setValid(false);
      }
    },
    [editor, monaco]
  );

  return (
    <EditorContext.Provider
      value={{
        isReady,
        hasChanged,
        markers,
        valid,
        hasErrors,
        setEditorObject,
        getEditorValue,
        setEditorValue,
        formatDocument,
        jumpTo,
        enableEdits,
        disableEdits
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext(EditorContext);
