// Framework and third-party non-ui
import styled from 'styled-components';

export const StyledToolbarWrapper = styled.div`
  width: 100%;
  background-color: var(--calcite-ui-foreground-1);
`;

export const StyledToolbar = styled.div`
  width: var(--content-width);
  max-width: var(--content-max-width);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.5rem 0;
  margin: 0 auto;
  .col-1 {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid var(--calcite-ui-border-3);
  }
  .col-2 {
    flex-grow: 1;
  }
  .col-3 {
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 1px solid var(--calcite-ui-border-2);
  }
`;
