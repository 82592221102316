// Framework and third-party non-ui
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// App components

// JSON & Styles
import {
  StyledDrawerBody,
  StyledDrawerFooter,
  StyledAccountTile,
  StyledAccountSectionHeader,
  StyledButtonWrapper,
  StyledDividingLine,
  StyledPopoverWrapper
} from '../AccountDrawer-styled';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { deployment } from 'config';

// Third-party components (buttons, icons, etc.)
import ComboButton from 'calcite-react/ComboButton';
import Menu, { MenuItem } from 'calcite-react/Menu';
import Button from 'calcite-react/Button';
import Popover from 'calcite-react/Popover';
import CaretDownIcon from 'calcite-ui-icons-react/CaretDownIcon';

function AccountTiles({
  isAccountDrawer = true,
  onAccountSelected,
  copyToAccount
}) {
  // ----- Language -----
  const { t } = useTranslation();

  // ----- Accounts Context -----
  const {
    selectedAccount,
    getUnselectedAccountsList,
    signIn,
    signOut,
    signOutAll,
    remove,
    removeAll,
    refresh,
    setSelected
  } = useAccountsContext();

  const { closeAccountDrawer, openEnterpriseSignIn } = useUIContext();

  const [active, setActiveAccount] = useState(selectedAccount);
  const [accounts, setAccounts] = useState(getUnselectedAccountsList());
  const [addAccountPopoverIsOpen, setAddAccountPopoverIsOpen] = useState(false);
  const [copyWorkflow, setCopyWorkflow] = useState(null);

  const toggleAddAccountPopover = () => {
    setAddAccountPopoverIsOpen(!addAccountPopoverIsOpen);
  };

  useEffect(() => {
    setActiveAccount(selectedAccount);
    setAccounts(getUnselectedAccountsList());
  }, [selectedAccount, getUnselectedAccountsList]);

  // ----- Enterprise SignIn -----
  const handleEnterpriseSignIn = () => {
    closeAccountDrawer();
    openEnterpriseSignIn();
  };

  // ----- Copy Workflow: Add Account SignIn ----
  const handleCopyWorkflowSignIn = type => {
    setCopyWorkflow({
      type,
      active: true,
      accountsBeforeSignIn: accounts.length
    });
    toggleAddAccountPopover();
    if (type === 'enterprise') {
      handleEnterpriseSignIn();
    } else {
      signIn({}, false);
    }
  };

  // Select the most recent logged-in account if added during copy item workflow
  useEffect(() => {
    if (
      !isAccountDrawer &&
      copyWorkflow?.active &&
      copyWorkflow?.accountsBeforeSignIn !== accounts.length
    ) {
      const latestAccount = accounts?.reduce((a, b) =>
        a.user.lastLogin > b.user.lastLogin ? a : b
      );
      onAccountSelected(latestAccount);
      copyWorkflow?.type === 'enterprise' && openEnterpriseSignIn();
    }
  }, [accounts, isAccountDrawer, copyWorkflow]);

  return (
    <>
      {/* ---------- Copy item workflow ------------ */}
      {!isAccountDrawer && (
        <StyledPopoverWrapper>
          <Popover
            open={addAccountPopoverIsOpen}
            onRequestClose={toggleAddAccountPopover}
            closeOnSelect={true}
            placement="bottom-end"
            targetEl={
              <Button
                clear
                extraSmall
                icon={<CaretDownIcon size={16} />}
                onClick={toggleAddAccountPopover}
              >
                {t('Account.AddAccount')}
              </Button>
            }
          >
            <Menu small>
              {!deployment.offlineMode && (
                <MenuItem onClick={handleCopyWorkflowSignIn}>
                  {t('Account.AddArcGISAccount')}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => handleCopyWorkflowSignIn('enterprise')}
                data-testid={'HandleEnterpriseSignIn-test'}
              >
                {t('Account.AddEnterpriseAccount')}
              </MenuItem>
            </Menu>
          </Popover>
        </StyledPopoverWrapper>
      )}

      {/* ---------- If there are multi accounts ------- */}
      <StyledDrawerBody isAccountDrawer={isAccountDrawer}>
        {isAccountDrawer && (
          <StyledAccountSectionHeader>
            {t('Account.CurrentlyActiveAccount')}
          </StyledAccountSectionHeader>
        )}
        <StyledAccountTile
          selected={
            copyToAccount ? copyToAccount.user.id === active.user.id : false
          }
          {...(!isAccountDrawer && {
            onClick: () => onAccountSelected(active)
          })}
          actions={
            isAccountDrawer
              ? [
                  {
                    label: t('Account.RemoveAccount'),
                    onClick: () => remove(active)
                  },
                  {
                    label: active.token
                      ? t('Account.SignOut')
                      : t('Account.Refresh'),
                    onClick: () =>
                      active.token ? signOut(active) : refresh(active)
                  }
                ]
              : []
          }
          user={active?.user}
          isAuthenticated={active?.token ? true : false}
          orgName={active?.portal?.name}
          userThumbnail={active.userThumbnail}
          orgThumbnail={active.orgThumbnail}
          data-testid={'AccountTile-test-1'}
          clickable={true}
        />
        {isAccountDrawer && <StyledDividingLine />}
        {accounts?.length > 0 && (
          <>
            {isAccountDrawer && (
              <StyledAccountSectionHeader>
                {t('Account.RecentAccounts')}
              </StyledAccountSectionHeader>
            )}

            {accounts.map(account => {
              return (
                <StyledAccountTile
                  selected={
                    copyToAccount
                      ? copyToAccount.user.id === account.user.id
                      : false
                  }
                  {...(!isAccountDrawer && {
                    onClick: () => onAccountSelected(account)
                  })}
                  actions={
                    isAccountDrawer
                      ? [
                          {
                            label: t('Account.RemoveAccount'),
                            onClick: () => remove(account)
                          },
                          {
                            label: account.token
                              ? t('Account.SignOut')
                              : t('Account.Refresh'),
                            onClick: () =>
                              account.token
                                ? signOut(account)
                                : refresh(account)
                          },
                          {
                            label: t('Account.SwitchActiveAccount'),
                            onClick: () => setSelected(account)
                          }
                        ]
                      : []
                  }
                  user={account.user}
                  isAuthenticated={account?.token ? true : false}
                  orgName={account.portal?.name}
                  userThumbnail={account.userThumbnail}
                  orgThumbnail={account.orgThumbnail}
                  key={account.key}
                  data-testid={'AccountTile-test-2'}
                />
              );
            })}
          </>
        )}
        {/* ---------------------------------------------- */}
      </StyledDrawerBody>

      {isAccountDrawer && (
        <StyledDrawerFooter isAccountDrawer={isAccountDrawer}>
          <StyledButtonWrapper>
            <ComboButton
              fullWidth
              clear
              onClick={deployment.offlineMode ? handleEnterpriseSignIn : signIn}
              label={t(
                `Account.${
                  deployment.offlineMode
                    ? 'AddEnterpriseAccount'
                    : 'AddArcGISAccount'
                }`
              )}
            >
              <Menu>
                {!deployment.offlineMode && (
                  <MenuItem
                    onClick={handleEnterpriseSignIn}
                    data-testid={'HandleEnterpriseSignIn-test'}
                  >
                    {t('Account.AddEnterpriseAccount')}
                  </MenuItem>
                )}
                <MenuItem onClick={signOutAll}>
                  {t('Account.SignOutAll')}
                </MenuItem>
                <MenuItem onClick={removeAll}>
                  {t('Account.RemoveAll')}
                </MenuItem>
              </Menu>
            </ComboButton>
          </StyledButtonWrapper>
        </StyledDrawerFooter>
      )}
    </>
  );
}

export default AccountTiles;
