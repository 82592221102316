import React from 'react';

import { StyledItemResourceModal } from './ItemResourceModal-styled.js';

const ItemResourceModal = ({
  open,
  onRequestClose,
  title,
  actions,
  children
}) => {
  return (
    <StyledItemResourceModal
      open={open}
      overlayStyle={{ position: 'absolute' }}
      appElement={document.body}
      title={title}
      actions={actions}
      onRequestClose={onRequestClose}
    >
      {children}
    </StyledItemResourceModal>
  );
};

export default ItemResourceModal;
