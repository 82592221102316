const FILTER_GROUP_DEFAULT = 'FILTER_GROUP_DEFAULT';
const FILTER_GROUP_ACTIVE = 'FILTER_GROUP_ACTIVE';
const FILTER_ITEM_DEFAULT = 'FILTER_ITEM_DEFAULT';
const FILTER_ITEM_ACTIVE = 'FILTER_ITEM_ACTIVE';
const FILTER_ITEM_DISABLED = 'FILTER_ITEM_DISABLED';
const FILTER_ITEM_OPTION_DEFAULT = 'FILTER_ITEM_OPTION_DEFAULT';
const FILTER_ITEM_OPTION_ACTIVE = 'FILTER_ITEM_OPTION_ACTIVE';
const FILTER_ITEM_OPTION_DISABLED = 'FILTER_ITEM_OPTION_DISABLED';

export default {
  FILTER_GROUP_DEFAULT,
  FILTER_GROUP_ACTIVE,
  FILTER_ITEM_DEFAULT,
  FILTER_ITEM_ACTIVE,
  FILTER_ITEM_DISABLED,
  FILTER_ITEM_OPTION_DEFAULT,
  FILTER_ITEM_OPTION_ACTIVE,
  FILTER_ITEM_OPTION_DISABLED
};
