// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';

// JSON & Styles
import { StyledLazyLoader } from './LazyLoader-styled';

// Hooks, context, and constants';

// Third-party components (buttons, icons, etc.)

const LazyLoader = ({ delay = 500, ...props }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return showLoader ? <StyledLazyLoader {...props} /> : null;
};

export default LazyLoader;
