import styled, { css } from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Button from 'calcite-react/Button';
import { ItemFilter } from 'arcgis-item-browser';

export const StyledItemFilterWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledItemFilter = styled(ItemFilter)`
  width: 25%;
  padding-right: 2rem;
`;

export const ItemTableWrapper = styled.div`
  width: 100%;
`;

export const StyledItemLink = styled.div`
  max-width: 100%;
  display: inline-flex;
  align-items: center;

  img {
    margin-inline-end: 6px;
  }

  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFilterButton = styled(Button)`
  ${props =>
    props.areFiltersVisible &&
    css`
      background-color: var(--calcite-ui-foreground-current);
      border: 1px solid var(--calcite-ui-foreground-current);
      :hover {
        background-color: var(--calcite-ui-foreground-current);
        border: 1px solid var(--calcite-ui-foreground-current);
      }
    `}
`;
