// Framework and third-party non-ui
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Hooks, context, and constants
import Routes from 'constants/Routes';

// App components
import PageContainer from 'pages/PageContainer';
import NotFoundPage from 'pages/NotFoundPage';
import JsonTertiaryHeader from 'pages/ItemPages/JsonPage/JsonTertiaryHeader';
import ItemEditor from 'components/ItemEditor';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

// App-specific
import { EditorContextProvider } from './EditorContext';

const JsonEditorPage = ({ itemId }) => {
  // ----- Routing -----
  const paths = {
    json: Routes.item.children.json.path.replace(':itemId', itemId),
    description: Routes.item.children.json.children.description.path.replace(
      ':itemId',
      itemId
    ),
    data: Routes.item.children.json.children.data.path.replace(
      ':itemId',
      itemId
    )
  };

  // ----- Editor -----
  const editorOptions = {
    language: 'json',
    theme: 'dark',
    monacoConstructionOptions: { readOnly: true },
    value: ''
  };

  return (
    <>
      <EditorContextProvider>
        <JsonTertiaryHeader paths={paths} itemId={itemId} />
        <PageContainer darkBackground>
          <Switch>
            <Route exact path={paths.json}>
              <Redirect to={paths.description} />
            </Route>
            <Route
              path={paths.description}
              render={props => (
                <ItemEditor
                  {...props}
                  options={editorOptions}
                  type="itemDescription"
                />
              )}
            />
            <Route
              path={paths.data}
              render={props => (
                <ItemEditor
                  {...props}
                  options={editorOptions}
                  type="itemData"
                />
              )}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </PageContainer>
      </EditorContextProvider>
    </>
  );
};

export default JsonEditorPage;
