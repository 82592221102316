import styled from 'styled-components';

const StyledComingSoonPage = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
`;

const StyledIntro = styled.div`
  flex: 1 0 300px;
  height: 100px;
  padding: 20px;
  color: white;
  font-size: 3em;
  font-weight: var(--calcite-font-weight-light);

  img {
    margin: 50px;
    height: 50px;
  }
`;

export { StyledComingSoonPage, StyledIntro };
