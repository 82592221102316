// Framework and third-party non-ui
import React, { useState, useEffect } from 'react';

// Hooks, context, and constants
import useItemResourcesState from './hooks/useItemResourcesState';
import { useItemBrowserState, ItemTable } from 'arcgis-item-browser';
import { useAccountsContext } from 'contexts/AccountsContext';
import { useHistory, useLocation } from 'react-router-dom';
import Routes from 'constants/Routes';

// App Components
import PageContainer from 'pages/PageContainer';
import ItemResourceModal from './ItemResourceModal';
import ItemResourceModalContent from './ItemResourceModalContent';
import Toolbar from 'components/Toolbar';

// JSON & Styles
import {
  StyledItemResourcesPage,
  StyledToolbarBtnWrapper,
  StyledBtn,
  StyledDownloadBtn,
  StyledCalciteLoader,
  StyledLink,
  StyledRouterLink
} from './ItemResourcesPage-styled.js';

// Third-party components (buttons, icons, etc.)
import TrashIcon from 'calcite-ui-icons-react/TrashIcon';
import SwitchIcon from 'calcite-ui-icons-react/SwitchIcon';
import DownloadIcon from 'calcite-ui-icons-react/DownloadIcon';
import UploadToIcon from 'calcite-ui-icons-react/UploadToIcon';
import DownloadToIcon from 'calcite-ui-icons-react/DownloadToIcon';

//Utils
import { itemResourceResultsTableColumnConfig } from './utils/customItemResourcesData';
import {
  getItemResourceUrl,
  getFormattedResourceSize,
  trimPrefix,
  editableResourceFileTypes,
  getResourceFileExt
} from './utils/helper';
import {
  defaultUploadFileModalStatus,
  defaultFileToUpload,
  defaultError
} from './utils/itemResourceDefaultValues';
import { addItem, deleteItemResource } from './utils/itemResourceActions';
import { downloadFile } from '../../../utilities/urls';

const ItemResourcesPage = ({ itemId }) => {
  // Local Modal stateD33
  const [uploadFileModalStatus, setUploadFileModalStatus] = useState(
    defaultUploadFileModalStatus
  );

  const [fileToUploadPrefix, setFileToUploadPrefix] = useState('');
  const [downloadLoaderActive, setDownloadLoaderActive] = useState(undefined);

  const [fileToUpload, setFileToUpload] = useState(defaultFileToUpload);

  const [itemResourceModalError, setItemResourceModalError] = useState(
    defaultError
  );

  // Development implementation of URLSearchParams
  const searchParams = new URLSearchParams(window.location.search);

  const history = useHistory();
  const location = useLocation();

  // Accounts Context
  const { selectedAccount } = useAccountsContext();
  const userAccount = selectedAccount;

  // Func we call from hook whenever urlParams obj changes
  const onUrlParamsUpdated = newParams => {
    if (location.search !== `?${newParams.toString()}`) {
      history.replace(`${history.location.pathname}?${newParams.toString()}`);
    }
  };

  const itemResourcesOptions = {
    userAccount,
    urlSearchParams: searchParams,
    onUrlParamsUpdated
  };

  const {
    itemResourcesState,
    handleItemResourcesStateUpdate,
    handleGetItemResources,
    itemResourcesResults,
    handleAddItemResource,
    handleReplaceItemResource,
    handleDeleteItemResource,
    selectedResourceTitles,
    setSelectedResourceTitles,
    ...useItemResourcesStateProps
  } = useItemResourcesState(itemResourcesOptions);

  const { itemBrowserState } = useItemBrowserState(userAccount, {});

  useEffect(() => {
    const setInitialItemResources = async () => {
      await handleGetItemResources({
        id: itemId
      });
    };

    if (itemId) {
      setInitialItemResources();
    }
  }, [itemId]); // eslint-disable-line

  const downloadItemResourceAction = {
    icon: <DownloadIcon size={16} />,
    label: 'Download',
    onClick: resourceName => {
      const url = getItemResourceUrl({
        portal: userAccount?.session?.portal,
        itemId: itemResourcesResults?.id,
        resourceName,
        token: userAccount?.token
      });
      window.open(url);
    },
    id: 'downloaditemresource'
  };

  const deleteItemResourceAction = {
    icon: <TrashIcon size={16} />,
    label: 'Delete',
    onClick: async resource => {
      // If receiving multiple resources to delete,  set default ActionsModal and verify that user wishes to delete each one.

      // Run if resource(s) is/are selected via row checkbox selection
      if (resource.items) {
        setItemResourceModalError({
          action: 'verifyDeleteResource',
          error: `Are you sure you want to delete ${
            resource.items.length === 1
              ? `"${resource.items[0]}"`
              : 'selected resources'
          }?`,
          title: 'Delete Resource',
          resourceName: resource.items.length === 1 ? resource.items[0] : null,
          multipleResources: resource.items.length > 1 ? resource.items : null
        });
        // Run if resource is selected via resource action menu
      } else if (resource) {
        setItemResourceModalError({
          action: 'verifyDeleteResource',
          error: `Are you sure you want to delete ${resource}?`,
          title: 'Delete Resource',
          resourceName: resource || null,
          multipleResources: null
        });
      }
    },
    id: 'delete'
  };

  const replaceItemResourceAction = {
    icon: <SwitchIcon size={16} />,
    label: 'Replace',
    onClick: resourceName => {
      toggleUploadFileModalOpen('replace', resourceName);
    },
    id: 'replaceitemresource'
  };

  // The following editItemResourceAction has been temporarily disabled until the ItemBrowser has been updated to conditionally disable or not display certain actions in the popup ellipsis menu.  This functionality is desired because only item resources that allow editing should display the editItemResourceAction in its ellipsis menu

  // const editItemResourceAction = {
  //   icon: <SwitchIcon size={16} />,
  //   label: 'Edit Resource Text',
  //   onClick: resourceName => {
  //     const resourceFileExt = getResourceFileExt(resourceName);

  //     if (editableResourceFileTypes.includes(resourceFileExt)) {
  //       const pathname = `${Routes.item.children.resources.children.itemResourcesEditor.path
  //         .replace(':itemId', itemId)
  //         .replace(':resourceName', resourceName)}`;
  //       history.push({
  //         pathname,
  //         state: { queryScope: searchParams.get('queryScope') }
  //       });
  //     }
  //   },
  //   id: 'edititemresource'
  // };

  const itemResourceActions = [
    downloadItemResourceAction,
    deleteItemResourceAction,
    replaceItemResourceAction
    // editItemResourceAction
  ];

  // Modal functionality
  const closeModal = () => {
    setUploadFileModalStatus(defaultUploadFileModalStatus);
    setItemResourceModalError(defaultError);
    setFileToUpload(defaultFileToUpload);
    setFileToUploadPrefix('');
  };

  const toggleUploadFileModalOpen = (action, itemResourceId) => {
    setUploadFileModalStatus({
      action,
      open: !uploadFileModalStatus.open,
      itemResourceId
    });
  };

  //If file already exists as resource, notify user
  const setModalErrorAndUploadStatus = (fileName, action) => {
    const error = `Resource with file name "${fileName}" already exists.  Clicking "Confirm" will overwrite existing file.`;

    setItemResourceModalError({
      action: action ? action : 'add',
      error,
      resourceName: fileName,
      title: 'Upload File'
    });

    setUploadFileModalStatus({
      ...uploadFileModalStatus,
      action,
      itemResourceId: fileName
    });
  };

  const checkIfFileAlreadyExists = (fileName, action) => {
    const existingFile = itemResourcesResults?.results?.find(
      resource => resource.resource === fileName
    );

    // If file with same name as file being uploaded exists, notify user that confirming upload will overwrite existing file with the one being uploaded
    if (existingFile) {
      setModalErrorAndUploadStatus(fileName, action);
    } else {
      setItemResourceModalError(defaultError);
    }
  };

  const handleSetFileToUploadPrefix = prefix => {
    if (fileToUploadPrefix !== prefix) {
      setFileToUploadPrefix(prefix);

      // If file has already been selected for upload, check if file with supplied prefix already exists
      if (fileToUpload.file) {
        const fileName = `${prefix}/${fileToUpload.file.name}`;
        checkIfFileAlreadyExists(fileName);
      }
    }
  };

  // Handles selection of file to upload.  Sets selected file to state, but must click Confirm button to complete upload
  const handleUploadFileSelect = (e, action) => {
    e.stopPropagation();

    if (e.currentTarget?.files) {
      const file = e.currentTarget.files[0];
      let fileName = file?.name;

      if (fileToUploadPrefix) {
        fileName = `${fileToUploadPrefix}/${fileName}`;
      }

      setFileToUpload({
        action: action ? action : 'add',
        file
      });

      checkIfFileAlreadyExists(fileName, action);
    }
  };

  // Handles click of button that adds/replaces item resource
  const handleBtnConfirmClick = () => {
    const { resourceName, multipleResources } = itemResourceModalError || {};

    if (itemResourceModalError?.action?.includes('verify')) {
      // If only 1 resource is selected for deletion, delete it

      if (resourceName && !multipleResources) {
        deleteItemResource({
          itemResourcesResults,
          itemResourceModalError,
          handleGetItemResources,
          handleDeleteItemResource,
          setItemResourceModalError,
          closeModal
        });
        // If multiple resource are selected for deletion, delete all of them
      } else if (multipleResources && !resourceName) {
        multipleResources.forEach(resource => {
          deleteItemResource({
            itemResourcesResults,
            itemResourceModalError,
            handleGetItemResources,
            handleDeleteItemResource,
            setItemResourceModalError,
            closeModal,
            resourceName: resource
          });
        });
      }
    } else {
      addItem({
        itemId,
        uploadFileModalStatus,
        fileToUpload,
        handleReplaceItemResource,
        handleGetItemResources,
        handleAddItemResource,
        setItemResourceModalError,
        closeModal,
        fileToUploadPrefix: trimPrefix(fileToUploadPrefix)
      });
    }
  };

  // ItemResourceModal prop values
  const open =
    uploadFileModalStatus.open || itemResourceModalError.error !== null;

  const title = uploadFileModalStatus.open
    ? 'Upload File'
    : itemResourceModalError.title;

  //ItemResourceModal action buttons
  const confirmButton = (
    <StyledBtn
      key="confirm-btn"
      onClick={handleBtnConfirmClick}
      disabled={
        !fileToUpload.file &&
        !itemResourceModalError?.action?.includes('verify')
      }
    >
      Confirm
    </StyledBtn>
  );

  const cancelButton = (
    <StyledBtn key="cancel-btn" halo onClick={closeModal}>
      {itemResourceModalError.error &&
      !itemResourceModalError.action.includes('verify')
        ? 'Close'
        : 'Cancel'}
    </StyledBtn>
  );

  // Do not show confirm button if there is an error or if verifying a request to delete
  const itemResourceModalActions =
    itemResourceModalError.error ||
    !itemResourceModalError?.action?.includes('verify')
      ? [confirmButton, cancelButton]
      : [cancelButton];

  // Custom Cell Renderers for Item Resources table
  const customNameRenderer = ({ cellData, rowData }) => {
    const resourceName = rowData?.resource;
    const resourceFileExt = getResourceFileExt(resourceName);

    // If item resource has editable file type, send user to editor page via /resources route. Otherwise, open file in new browser tab, via portal URL
    if (editableResourceFileTypes.includes(resourceFileExt)) {
      const resourceRoute = Routes.item.children.resources.path.replace(
        ':itemId',
        itemId
      );

      return (
        <StyledRouterLink
          to={`${resourceRoute}?file=${resourceName}`}
          title={cellData}
        >
          {resourceName}
        </StyledRouterLink>
      );
    } else {
      const href = getItemResourceUrl({
        portal: userAccount?.session?.portal,
        itemId: itemResourcesResults?.id,
        resourceName,
        token: userAccount?.token
      });

      return (
        <StyledLink href={href} title={cellData} target={'_blank'}>
          {resourceName}
        </StyledLink>
      );
    }
  };

  const customSizeRenderer = ({ cellData }) => {
    return getFormattedResourceSize(cellData);
  };

  const customCellRenderer = {
    name: customNameRenderer,
    size: customSizeRenderer
  };

  const setExportResourcesError = error => {
    const errorParams = {
      action: 'itemResourceDownloadError',
      error: error.message,
      title: 'Error downloading'
    };
    setItemResourceModalError(errorParams);
  };

  const exportAllResources = async () => {
    try {
      setDownloadLoaderActive(true);
      const url = `https://www.arcgis.com/sharing/rest/content/items/${itemId}/resources/export`;
      const format = 'zip';
      const token = userAccount?.token;
      const filename = 'resources.zip';

      const params = {
        url,
        format,
        token,
        filename,
        onError: setExportResourcesError
      };

      await downloadFile(params);

      setDownloadLoaderActive(undefined);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <>
      <Toolbar
        centerContent={
          <StyledToolbarBtnWrapper>
            <StyledBtn
              clear
              icon={<UploadToIcon size={16} />}
              iconPosition="before"
              onClick={() => toggleUploadFileModalOpen('add', null)}
              small
            >
              Upload File
            </StyledBtn>

            <StyledDownloadBtn
              icon={<DownloadToIcon size={16} />}
              iconPosition="before"
              onClick={() => exportAllResources()}
              small
            >
              Download All
            </StyledDownloadBtn>
            <StyledCalciteLoader active={downloadLoaderActive} inline />
          </StyledToolbarBtnWrapper>
        }
      />

      <PageContainer>
        <StyledItemResourcesPage>
          {' '}
          <ItemResourceModal
            open={open}
            title={title}
            actions={itemResourceModalActions}
            onRequestClose={closeModal}
          >
            <ItemResourceModalContent
              itemResourceModalError={itemResourceModalError}
              uploadFileModalStatus={uploadFileModalStatus}
              handleUploadFileSelect={handleUploadFileSelect}
              setItemResourceModalError={setItemResourceModalError}
              fileName={fileToUpload?.file?.name}
              prefix={fileToUploadPrefix}
              handleSetFileToUploadPrefix={handleSetFileToUploadPrefix}
            />
          </ItemResourceModal>
          <ItemTable
            queryResults={itemResourcesResults}
            actions={[deleteItemResourceAction]}
            singleItemActions={itemResourceActions}
            selectedItemIds={selectedResourceTitles}
            setSelectedItemIds={setSelectedResourceTitles}
            {...useItemResourcesStateProps}
            handleQueryChange={handleGetItemResources}
            itemBrowserState={itemBrowserState}
            customTableColumnConfig={itemResourceResultsTableColumnConfig}
            customCellRenderer={customCellRenderer}
            customItemIdKey={'resource'}
            // Users can pass customItemIdKey to ItemTable to reference and identifier on each item's rowData prop.  Key defaults to "id"
          />
        </StyledItemResourcesPage>
      </PageContainer>
    </>
  );
};

export default ItemResourcesPage;
