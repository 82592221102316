// Framework and third-party non-ui
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';

// App components
import PageContainer from '../PageContainer';

// JSON & Styles

// Third-party components (buttons, icons, etc.)

const AuthorizePage = props => {
  // ----- Routing -----
  const [active, setActive] = useState();
  const history = useHistory();
  const hash = useAuthHash();

  // ----- Accounts -----
  const { selectedAccount, getOriginRoute } = useAccountsContext();

  useEffect(() => {
    if (active) {
      const route = getOriginRoute();
      const path = route === '/signin' ? 'content' : route;
      history.push(path);
    }
    if (hash) {
      if (hash.includes('token')) {
        setActive(selectedAccount);
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  }, [active, selectedAccount, getOriginRoute, hash, history]);

  return <PageContainer />;
};

//
// HELPERS
//

const useAuthHash = () => {
  const hash = useLocation().hash;
  return hash;
};

export default AuthorizePage;
