// Framework and third-party non-ui
import styled from 'styled-components';

// Third-party components (buttons, icons, etc.)

const StyledItemTitle = styled.h2`
  margin: 0 0 0.5rem 0;
  font-weight: var(--calcite-font-weight-normal);
  line-height: 1.5;
  font-size: var(--calcite-font-size-1);
  word-break: break-all;
`;

const StyledItemImgWrapper = styled.div`
  height: 200px;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem);
  box-sizing: border-box;
  background-color: var(--calcite-ui-background);
  overflow: hidden;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border: 1px solid var(--calcite-ui-border-2);
  }
`;

const StyledItemType = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  img,
  svg {
    margin-inline-end: 0.25rem;
    margin-top: 4px;
    height: fit-content;
  }
  p,
  a {
    white-space: nowrap;
  }
  p {
    margin: 0;
    word-break: break-all;
    font-size: var(--calcite-font-size--1);
  }
  a {
    white-space: normal;
  }
`;
const StyledCopyToClipBoard = styled.div`
  margin-bottom: 1rem;
  label {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledButtonWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  .margin-bottom {
    margin-bottom: 0.5rem;
  }
`;

export {
  StyledItemTitle,
  StyledItemImgWrapper,
  StyledItemType,
  StyledCopyToClipBoard,
  StyledButtonWrapper
};
