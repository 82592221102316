// Framework and third-party non-ui
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { deployment } from 'config';

// Third-party components (buttons, icons, etc.)
import Menu, { MenuItem } from 'calcite-react/Menu';
import Popover from 'calcite-react/Popover';
import { StyledTopNavLink } from '../Header-styled';

function HeaderSignIn() {
  const [signInPopoverIsOpen, setSignInPopoverIsOpen] = useState(false);
  const { signIn } = useAccountsContext();
  const { openEnterpriseSignIn } = useUIContext();

  const { t } = useTranslation();

  const toggleSignInPopover = () =>
    setSignInPopoverIsOpen(!signInPopoverIsOpen);

  const signInOnline = () => {
    toggleSignInPopover();
    signIn();
  };

  const signInEnterprise = () => {
    toggleSignInPopover();
    openEnterpriseSignIn();
  };

  return (
    <Popover
      targetEl={
        <StyledTopNavLink onClick={toggleSignInPopover}>
          {t('Account.SignIn')}
        </StyledTopNavLink>
      }
      open={signInPopoverIsOpen}
      onRequestClose={toggleSignInPopover}
      placement="bottom-end"
    >
      <Menu>
        {!deployment.offlineMode && (
          <MenuItem onClick={signInOnline}>{t('Account.SignInAGO')}</MenuItem>
        )}
        {/* TODO: add support for Enterprise */}
        <MenuItem onClick={signInEnterprise}>
          {t('Account.SignInEnterprise')}
        </MenuItem>
      </Menu>
    </Popover>
  );
}

export default HeaderSignIn;
