import React from 'react';
import {
  StyledForm,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormHelperText,
  StyledH5,
  StyledPrefixInput,
  StyledFileUploader,
  StyledAlert,
  StyledAlertMessage
} from './ItemResourceModalContent-styled.js';
import { defaultError } from '../utils/itemResourceDefaultValues';
import { trimPrefix } from '../utils/helper';

const ItemResourceModalContent = ({
  itemResourceModalError,
  uploadFileModalStatus,
  handleUploadFileSelect,
  setItemResourceModalError,
  fileName,
  prefix,
  handleSetFileToUploadPrefix
}) => {
  const isInReplaceModeWithPrefix =
    uploadFileModalStatus.action === 'replace' &&
    uploadFileModalStatus.itemResourceId.includes('/');

  if (isInReplaceModeWithPrefix) {
    const pathSegments = uploadFileModalStatus.itemResourceId.split('/');
    pathSegments.pop();
    const existingPrefix = pathSegments.join('/');

    handleSetFileToUploadPrefix(existingPrefix);
  }

  switch (true) {
    case itemResourceModalError?.action === 'read':
    case itemResourceModalError?.action === 'delete':
      return <StyledH5 color={'red'}>{itemResourceModalError?.error}</StyledH5>;

    case itemResourceModalError?.action === 'verifyDeleteResource':
    case itemResourceModalError?.action === 'itemResourceDownloadError':
      return <StyledH5>{itemResourceModalError?.error}</StyledH5>;

    // If upload modal is open render FileUploader
    case uploadFileModalStatus.open:
      return (
        <StyledForm>
          <StyledFormControl>
            <StyledFormControlLabel>
              Enter optional folder name:
            </StyledFormControlLabel>
            <StyledPrefixInput
              fullWidth
              placeholder="e.g. images/widget1"
              disabled={isInReplaceModeWithPrefix}
              value={prefix}
              onChange={e => handleSetFileToUploadPrefix(e.target.value)}
            />
          </StyledFormControl>
          <StyledFormControl>
            <StyledFormControlLabel>
              Select file to upload:
            </StyledFormControlLabel>

            <StyledFileUploader
              fullWidth
              onChange={event => {
                // Specifies if file upload will add new file or replace existing file
                setItemResourceModalError(defaultError);
                const action =
                  uploadFileModalStatus?.action === 'replace'
                    ? uploadFileModalStatus.action
                    : null;

                handleUploadFileSelect(event, action);
              }}
            />

            <StyledFormHelperText>
              <code>
                {prefix &&
                  fileName &&
                  `Name: ${trimPrefix(prefix)}/${fileName}`}
              </code>
            </StyledFormHelperText>
          </StyledFormControl>
          {itemResourceModalError?.error && (
            <StyledAlert full yellow>
              <StyledAlertMessage>
                {itemResourceModalError?.error}
              </StyledAlertMessage>
            </StyledAlert>
          )}
        </StyledForm>
      );
    default:
      return null;
  }
};

export default ItemResourceModalContent;
