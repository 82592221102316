// Framework and third-party non-ui
import styled from 'styled-components';

// Third-party components (buttons, icons, etc.)
import Loader from 'calcite-react/Loader';

export const StyledLazyLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
