export const getItemResourceUrl = ({ portal, itemId, resourceName, token }) => {
  let url = '';
  if (portal && itemId && resourceName && token) {
    url = `${portal}/content/items/${itemId}/resources/${resourceName}?token=${token}`;
  }

  return url;
};

export const getFormattedResourceSize = size => {
  if (size < 1000) {
    return `${size} B`;
  }

  return `${Math.round(size / 1000)} KB`;
};

export const trimPrefix = prefix => {
  return prefix
    .split('/')
    .filter(folder => !!folder)
    .join('/');
};

export const editableResourceFileTypes = ['json', 'txt', 'xml'];

export const resourceFileTypes = {
  json: 'application/json',
  xml: 'application/xml',
  txt: 'text/plain'
};

export const fileExtensions = {
  json: 'json',
  xml: 'xml',
  txt: 'txt'
};

export const getResourceFileExt = resourceName => {
  const splitResourceName = resourceName?.split('.');
  const resourceFileExt = splitResourceName[splitResourceName.length - 1];
  return resourceFileExt;
};
