import stateTypes from './stateTypes';

export const getStore = (dataArr, defaultState) => {
  const newStore = dataArr.reduce((acc, currentValue) => {
    if (!acc[currentValue.id]) {
      acc[currentValue.id] = {
        ...currentValue,
        state: defaultState
      };
    }
    return acc;
  }, {});

  return { byId: newStore, allIds: Object.keys(newStore) };
};

export const getInitialStore = ({
  filterGroups,
  filterItems,
  filterItemOptions
}) => {
  return {
    filters: {
      groups: getStore(filterGroups, stateTypes.FILTER_GROUP_DEFAULT),
      items: getStore(filterItems, stateTypes.FILTER_ITEM_DEFAULT),
      itemOptions: getStore(
        filterItemOptions,
        stateTypes.FILTER_ITEM_OPTION_DEFAULT
      )
    }
  };
};
