const Routes = {
  root: { path: '/' },
  comingSoon: {
    path: '/coming-soon',
    name: 'Coming Soon',
    id: 'comingSoon',
    description: ''
  },
  content: {
    path: '/content',
    name: 'Content',
    id: 'content',
    description: ''
  },
  sandbox: {
    path: '/sandbox',
    name: 'Sandbox',
    id: 'sandbox',
    description: ''
  },
  signin: {
    path: '/signin',
    name: 'Sign In',
    id: 'signin',
    description: ''
  },
  authorize: {
    path: '/authorize',
    name: 'Authorize',
    id: 'authorize',
    description: ''
  },
  item: {
    path: '/item/:itemId',
    name: 'Item',
    id: 'item',
    description: '',
    children: {
      json: {
        name: 'JSON',
        id: 'json',
        path: '/item/:itemId/json',
        description: '',
        children: {
          description: {
            name: 'Description JSON Editor',
            id: 'description',
            path: '/item/:itemId/json/description',
            description: ''
          },
          data: {
            name: 'Data JSON Editor',
            id: 'data',
            path: '/item/:itemId/json/data',
            description: ''
          }
        }
      },
      resources: {
        name: 'Resources',
        id: 'resources',
        path: '/item/:itemId/resources',
        description: ''
      }
    }
  }
};

export default Routes;
