// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// React
import React from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';

// React Router
import { BrowserRouter, Route } from 'react-router-dom';

// Internationalization
import 'utilities/i18n';

// Calcite React
import CalciteThemeProvider from 'calcite-react/CalciteThemeProvider';
import { ToastContainer } from 'calcite-react/Toaster';

// PWA
import * as serviceWorker from './serviceWorker';

// App-specific
import { homepage } from '../package.json';
import { deployment } from 'config';
import App from './App';
import { AccountsContextProvider } from 'contexts/AccountsContext';
import { ItemContextProvider } from 'contexts/ItemContext';
import { UIContextProvider } from 'contexts/UIContext';
import LazyLoader from './components/LazyLoader';

import GlobalStyle from './GlobalStyle.js';
import {
  applyPolyfills,
  defineCustomElements
} from '@esri/calcite-components/dist/loader';
import '@esri/calcite-components/dist/calcite/calcite.css';

// Apply polyfills and then define the custom elements
// polyfills are not needed if you don't support IE11 or Edge
applyPolyfills().then(() => {
  defineCustomElements(window);
});

const isProduction = process.env.NODE_ENV === 'production';
// App runs at the root locally, but under /{homepage} in production
let basename = '';

if (isProduction) {
  // Set basename appropriately for production build
  basename = homepage;

  if (!deployment.offlineMode) {
    // Initialize LogRocket
    // NOTE: Removing LogRocket for now
    // TODO: Implement LogRocket for the development team's non-production deployment environments
    // LogRocket.init('arcgis-assistant/assistant-test');
  }
}

ReactDOM.render(
  <>
    <GlobalStyle />
    <CalciteThemeProvider>
      <BrowserRouter basename={basename}>
        <AccountsContextProvider>
          <ItemContextProvider>
            <UIContextProvider>
              <Suspense fallback={<LazyLoader delay={750} />}>
                <ToastContainer />
                <Route path="/" component={App} />
              </Suspense>
            </UIContextProvider>
          </ItemContextProvider>
        </AccountsContextProvider>
      </BrowserRouter>
    </CalciteThemeProvider>
  </>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
