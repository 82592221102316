// Framework and third-party non-ui
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isServiceNameAvailable } from '@esri/arcgis-rest-portal';

// App components
import AccountTiles from 'components/AccountDrawer/AccountTiles';

// Redux operations and local helpers/utils/modules
import { resources } from 'arcgis-item-browser';

// Hooks, context, and constants
import { useAccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { useItemBrowserState, utils } from 'arcgis-item-browser';
import { copyItem } from 'arcgis-item-engine';
import { copy } from 'ItemSupport';

// Component specific modules (Component-styled, etc.)
import {
  StyledItemLink,
  StyledStepHeader,
  StyledStepPrompt,
  StyledInlineButton,
  StyledIconItem,
  StyledAccordionContent,
  StyledAlert,
  StyledAccountStep,
  StyledWarningCard,
  StyledReAuthButton
} from './CopyItems-styled';

// Third-party components (buttons, icons, etc.)
import { StyledAccountTile } from 'components/AccountDrawer/AccountDrawer-styled';
import { AlertMessage } from 'calcite-react/Alert';
import { CalciteA } from 'calcite-react/Elements';
import Loader from 'calcite-react/Loader';
import TextField from 'calcite-react/TextField';
import Button from 'calcite-react/Button';
import Accordion, {
  AccordionTitle,
  AccordionSection
} from 'calcite-react/Accordion';
import Form, { FormControl, FormControlLabel } from 'calcite-react/Form';
import { CardContent } from 'calcite-react/Card';
import Checkbox from 'calcite-react/Checkbox';

// todo update arcgis-item-browser-dependency
import Users from 'arcgis-item-browser/dist/components/Users';
import Folders from 'arcgis-item-browser/dist/components/Folders';
import CreateNewFolder from 'arcgis-item-browser/dist/components//CreateNewFolder';

import ExclamationMarkTriangleFIcon from 'calcite-ui-icons-react/ExclamationMarkTriangleFIcon';
import UserIcon from 'calcite-ui-icons-react/UserIcon';
import FolderIcon from 'calcite-ui-icons-react/FolderIcon';
import ArrowLeftIcon from 'calcite-ui-icons-react/ArrowLeftIcon';

// Lookup table to track debounce timers for service name checks
const serviceNameTimers = {};

const CopyItems = ({ items }) => {
  // ----- Language -----
  const { t } = useTranslation();

  // Find items of invalid types (cannot fully copy)
  const partialSupport = [];
  items.forEach(item => {
    if (copy?.partial?.[item?.type]) {
      const { id, title, type } = item || {};
      partialSupport.push({
        id,
        title,
        type
      });
    }
  });

  // ----- Accounts Context -----
  const {
    selectedAccount,
    setSelected,
    refresh,
    accountsList
  } = useAccountsContext();

  // ----- Item Drawer State -----
  const { itemDrawerState, setItemDrawerState } = useUIContext();
  const viewItemDetails = item => {
    setItemDrawerState({
      ...itemDrawerState,
      items: [item],
      view: 'ItemDetails'
    });
  };

  // ----- Post Re-Authorization Token Refresh -----
  useEffect(() => {
    const contextAccount = accountsList.filter(
      acct => acct.user.id === copyState.account.user.id
    )[0];
    if (contextAccount && contextAccount?.token !== copyState.account.token) {
      setCopyState({
        ...copyState,
        account: contextAccount
      });
    }
  }, [accountsList]);

  // ----- Copy State -----
  const [copyState, setCopyState] = useState({
    items: items.map((item, i) => {
      return {
        itemDetails: item,
        copyDetails: {
          id: item?.id,
          title: null,
          defaultTitle: item?.title,
          titleValid: true,
          deep: true,
          // todo warning for large items
          ...(false && { warning: t('Item.Copy.SizeWarning') })
        },
        isOpen: i === 0,
        isRemoved: false
      };
    }),
    step: 'account',
    account: selectedAccount,
    user: null,
    folder: null,
    removedItem: null,
    submitStatus: null,
    successMessage: null,
    errorMessage: null,
    invalidTypeNotice:
      partialSupport.length !== 0 ? (
        <StyledAlert
          yellow
          showIcon
          showCloseLabel
          onClose={() => {
            setCopyState({ ...copyState, isInvalidTypeNoticeDismissed: true });
          }}
        >
          <AlertMessage style={{ fontSize: '14px', lineHeight: 1.25 }}>
            {`${t('Item.Copy.TypeWarning')} ${Array.from(
              // reduce to unique types
              new Set(partialSupport.map(i => i.type))
            ).join(', ')} `}
          </AlertMessage>
        </StyledAlert>
      ) : null,
    isInvalidTypeNoticeDismissed: false
  });

  const steps = [
    'account',
    ...(copyState.account.user?.privileges?.includes('portal:admin:updateItems')
      ? ['user']
      : []),
    'folder',
    'review'
  ];

  const { itemBrowserState, ...itemBrowserProps } = useItemBrowserState(
    copyState.account,
    {
      showFolderFilter: false,
      showUserFilter: false
    }
  );

  const getFolders = () => {
    // prevent copying to allmycontent
    const exclude = 'allmycontent';
    let byId = { ...itemBrowserState?.folders.items.byId };
    delete byId[exclude];
    const folders = {
      ...itemBrowserState?.folders,
      items: {
        allIds: itemBrowserState?.folders.items.allIds.filter(
          id => id !== exclude
        ),
        byId
      }
    };
    return folders;
  };

  // -- Account Step --
  const onAccountSelected = account => {
    setCopyState({
      ...copyState,
      account,
      // clear folder and user
      folder: null,
      user: null
    });
  };

  // -- User Step --
  const onUserItemClick = user => {
    setCopyState({
      ...copyState,
      user: itemBrowserState.users.items.byId[user]
    });
  };

  // -- Folder Step --
  const [createFolderActive, setCreateFolderActive] = useState(false);
  const createNewFolder = (
    <CreateNewFolder
      setCreateFolderActive={setCreateFolderActive}
      createFolderActive={createFolderActive}
      userSession={copyState.account.session}
      {...itemBrowserProps}
    />
  );
  const onFolderItemClick = folder => {
    setCopyState({
      ...copyState,
      folder: itemBrowserState.folders.items.byId[folder]
    });
  };

  // -- Steps Logic --
  const nextStep = () => {
    const folders = getFolders().items;
    const users = itemBrowserState.users.items;
    const step = steps[steps.indexOf(copyState.step) + 1];
    setCopyState({
      ...copyState,
      step,
      successMessage: null,
      errorMessage: null,
      // set selected folder to first folder under account
      // if entering folder step
      ...(step === 'folder' && copyState.step !== 'review'
        ? { folder: folders.byId[folders.allIds[0]] }
        : {}),
      // same with user
      ...(step === 'user' && copyState.step !== 'review'
        ? { user: users.byId[users.allIds[0]] }
        : {})
    });
  };

  const onAccordionChange = (e, index) => {
    let items = [...copyState.items];
    let item = { ...items.filter(i => !i.isRemoved)[index] };
    item.isOpen = !item.isOpen;
    items[index] = item;
    setCopyState({
      ...copyState,
      items
    });
  };

  const removeItem = item => {
    let items = [...copyState.items];
    let i = items.find(i => i.itemDetails.id === item.itemDetails.id);
    i.isRemoved = true;
    setCopyState({
      ...copyState,
      items,
      removedItem: i
    });
  };

  const undo = () => {
    let items = [...copyState.items];
    let i = items.find(
      i => i.itemDetails.id === copyState.removedItem.itemDetails.id
    );
    i.isRemoved = false;
    setCopyState({
      ...copyState,
      items,
      removedItem: null
    });
  };

  const areButtonsEnabled =
    copyState.items.filter(i => !i.isRemoved).length !== 0 &&
    (copyState.step !== 'review' ||
      copyState.items.every(
        item => !item.copyDetails.deep || item.copyDetails.titleValid
      )) &&
    copyState.submitStatus !== 'PENDING';

  const validateServiceName = useCallback(
    (item, account) => {
      const title = item.copyDetails.title || item.copyDetails.defaultTitle;
      const timerName = `${item.itemDetails.id}_${account.key}_${title}`;
      // clear timers by item id (except duplicate title)
      Object.entries(serviceNameTimers).forEach(([key, timer]) => {
        if (key.startsWith(item.itemDetails.id) && key !== timerName) {
          clearTimeout(timer);
          delete serviceNameTimers[key];
        }
      });

      // ensure timer for current title
      if (serviceNameTimers[timerName] === undefined) {
        serviceNameTimers[timerName] = setTimeout(async () => {
          // check service name availability
          const { available } = await isServiceNameAvailable(
            title,
            item.itemDetails.type,
            account.session
          );
          item.copyDetails.titleValid = available;

          // trigger re-render
          setCopyState({ ...copyState });
        }, 300);
      }
    },
    [copyState]
  );
  useEffect(() => {
    copyState.items.forEach(item => {
      if (copy?.deep_supported?.[item.itemDetails.type]) {
        validateServiceName(item, copyState.account);
      }
    });
  }, [copyState, validateServiceName]);

  const submit = () => {
    // todo Add copyState to local storage
    setCopyState({
      ...copyState,
      submitStatus: 'PENDING',
      successMessage: null,
      errorMessage: null
    });

    const copyOperations = copyState.items.map(
      item =>
        new Promise(async (resolve, reject) => {
          try {
            const res = await copyItem(
              // ----- Source Options -----
              // [IRequestOptions](https://esri.github.io/arcgis-rest-js/api/request/IRequestOptions/)
              {
                id: item?.copyDetails?.id, // source item id
                authentication: selectedAccount?.session, // todo current user session (if needed), otherwise use portal property
                // Set portal to AGO if public item
                ...(item.itemDetails.access === 'public' &&
                  !item.itemDetails.isOrgItem && {
                    portal: `${process.env.REACT_APP_PORTAL_URL}/sharing/rest`
                  })
              },

              // ----- Destination Options -----
              // (see [ICreateUpdateItemOptions](https://esri.github.io/arcgis-rest-js/api/portal/ICreateUpdateItemOptions/))
              {
                authentication: copyState.account.session,
                owner:
                  copyState.user?.username || copyState.account.user.username,
                ...(copyState.folder.id !== 'root' && {
                  folderId: copyState?.folder?.id
                })
              },

              // ----- Copy Options -----
              {
                data: true, // boolean, defaults to true, include data
                resources: true, // boolean, defaults to true, include resources
                description: {
                  title:
                    item?.copyDetails?.title || item?.copyDetails?.defaultTitle
                },
                service: item?.copyDetails?.deep ? { data: true } : false
              }
            );
            resolve(res);
          } catch (error) {
            console.warn(error);
            reject(error);
          }
        })
    );

    Promise.allSettled(copyOperations).then(results => {
      let rejected = [];
      let fulfilled = [];
      results.forEach(result => {
        result.status === 'fulfilled'
          ? fulfilled.push(result)
          : rejected.push(result);
      });

      setCopyState({
        ...copyState,
        // only leave items that failed to copy
        items: copyState.items.filter(item =>
          rejected
            .map(rej => rej.reason.options.params.id)
            .includes(item.itemDetails.id)
        ),
        submitStatus: 'COMPLETE',
        successMessage:
          fulfilled.length > 0 ? (
            <StyledAlert green showIcon>
              <AlertMessage>
                {t('Item.Copy.CopySuccess', { count: fulfilled.length })}
                {fulfilled.length === 1 ? (
                  <CalciteA
                    style={{ display: 'block' }}
                    target="_blank"
                    href={`${utils.helper.getItemOrgPageUrl(
                      copyState.account.portal,
                      fulfilled[0].value.id
                    )}`}
                  >
                    {t('Item.OpenInAGO')}
                  </CalciteA>
                ) : (
                  <CalciteA
                    style={{ display: 'block' }}
                    onClick={() => {
                      // Set to appropriate active account
                      if (selectedAccount.key !== copyState.account.key) {
                        setSelected(copyState.account);
                      }
                      // open folder that items were copied to
                      window.open(
                        `/content?queryScope=USER&folder=${copyState.folder.id}`,
                        '_self'
                      );
                    }}
                  >
                    {t('Item.Copy.ViewItems', {
                      count: fulfilled.length
                    })}
                  </CalciteA>
                )}
              </AlertMessage>
            </StyledAlert>
          ) : null,
        errorMessage:
          rejected.length > 0 ? (
            <>
              {rejected.map((rej, index) => (
                <StyledAlert red showIcon key={index}>
                  <AlertMessage>{rej.reason.message}</AlertMessage>
                </StyledAlert>
              ))}
            </>
          ) : null
      });

      // Refresh item browser
      itemDrawerState?.onActionComplete && itemDrawerState.onActionComplete();
    });
  };

  const viewConfig = () => {
    let config = {
      showItemList: true,
      title: t('Item.Copy.CopyDestination'),
      prompt: null,
      actionLabel: t('App.Continue'),
      action: () => nextStep()
    };
    switch (copyState.step) {
      case 'account':
        return {
          ...config,
          prompt: t('Item.Copy.ChooseAccount'),
          actionLabel: t('Item.Copy.SelectAccount')
        };
      case 'user':
        return {
          ...config,
          prompt: t('Item.Copy.ChooseUser'),
          actionLabel: t('Item.Copy.SelectUser')
        };
      case 'folder':
        return {
          ...config,
          prompt: t('Item.Copy.ChooseFolder'),
          actionLabel: t('Item.Copy.SelectFolder')
        };
      case 'review':
        return {
          ...config,
          prompt: t('Item.Copy.ReviewItems', {
            count: copyState.items.length
          }),
          actionLabel: t('Item.Copy.CopyItems', {
            count: copyState.items.length
          }),
          action: () => submit()
        };
      default:
        return config;
    }
  };

  const backButton = step => (
    <StyledInlineButton
      margin
      onClick={() => setCopyState({ ...copyState, step })}
    >
      <ArrowLeftIcon size={16} style={{ marginRight: '0.25rem' }} />
      {t(`Item.Copy.Change${step.charAt(0).toUpperCase() + step.slice(1)}`)}
    </StyledInlineButton>
  );

  const selectedAccountTile = (
    <StyledAccountTile
      tabindex="0"
      user={copyState.account.user}
      isAuthenticated={copyState.account.user ? true : false}
      orgName={copyState.account.portal?.name}
      userThumbnail={copyState.account.userThumbnail}
      orgThumbnail={copyState.account.orgThumbnail}
      clickable={false}
      hideAuthentication={true}
    />
  );

  return (
    <>
      {/* ---------- Copying Items ------- */}
      {items.length <= 6 && copyState.step !== 'review' && (
        <div style={{ marginTop: '-0.75rem' }}>
          {copyState.items.map(item => (
            <StyledItemLink key={item.itemDetails.id} small>
              {resources.helper.getItemTypeIcon(item.itemDetails)}
              <CalciteA
                onClick={() => viewItemDetails(item.itemDetails)}
                title={item.itemDetails.title}
              >
                {item.itemDetails.title}
              </CalciteA>
            </StyledItemLink>
          ))}
        </div>
      )}
      {!copyState.isInvalidTypeNoticeDismissed && copyState.invalidTypeNotice}
      <StyledStepHeader>{viewConfig().title}</StyledStepHeader>

      {/* ---------- Select Account ------- */}
      {copyState.step === 'account' && (
        <StyledAccountStep>
          <StyledStepPrompt>{viewConfig().prompt}</StyledStepPrompt>
          <AccountTiles
            isAccountDrawer={false}
            onAccountSelected={onAccountSelected}
            copyToAccount={copyState.account}
          />
          {!copyState?.account?.token && (
            <StyledWarningCard bar="yellow">
              <CardContent>
                <p>{t('Account.AuthWarning')}</p>
                <StyledReAuthButton
                  transparent
                  onClick={() => {
                    refresh(copyState.account, true);
                  }}
                >
                  {t(`Account.ReAuth`)}
                </StyledReAuthButton>
              </CardContent>
            </StyledWarningCard>
          )}
        </StyledAccountStep>
      )}

      {/* ---------- Select User ------- */}
      {copyState.step === 'user' && (
        <>
          {backButton('account')}
          {selectedAccountTile}
          <StyledStepPrompt>{viewConfig().prompt}</StyledStepPrompt>
          <Users
            users={itemBrowserState?.users}
            userSession={itemBrowserProps.session}
            onUserItemClick={onUserItemClick}
            label={false}
            {...itemBrowserProps}
          />
        </>
      )}

      {/* ---------- Select Folder ------- */}
      {copyState.step === 'folder' && (
        <>
          {backButton(copyState.user ? 'user' : 'account')}
          {copyState.user ? (
            <StyledIconItem>
              <UserIcon size={16} />
              <p>{`${copyState.user.alias} (${copyState.user.title})`}</p>
            </StyledIconItem>
          ) : (
            selectedAccountTile
          )}
          <StyledStepPrompt>{viewConfig().prompt}</StyledStepPrompt>
          <Folders
            folders={getFolders()}
            selectedFolderId={copyState.folder?.id}
            onFolderItemClick={onFolderItemClick}
            label={false}
            setCreateFolderActive={setCreateFolderActive}
            createFolderActive={createFolderActive}
            createNewFolder={createNewFolder}
            userSession={copyState.account.session}
            {...itemBrowserProps}
          />
        </>
      )}

      {/* ---------- Confirmation ------- */}
      {copyState.step === 'review' && (
        <>
          <div style={{ position: 'relative', marginBottom: '0.5rem' }}>
            {areButtonsEnabled && (
              <StyledInlineButton
                onClick={() => setCopyState({ ...copyState, step: 'account' })}
                style={{ position: 'absolute', top: '0.75rem', right: 0 }}
              >
                {t('App.Change')}
              </StyledInlineButton>
            )}
            {selectedAccountTile}
          </div>
          {copyState.user && (
            <>
              <StyledIconItem style={{ marginBottom: '0.5rem' }}>
                <UserIcon size={16} />
                <p>{`${copyState.user.alias} (${copyState.user.title})`}</p>
                {areButtonsEnabled && (
                  <StyledInlineButton
                    margin
                    onClick={() => setCopyState({ ...copyState, step: 'user' })}
                  >
                    {t('App.Change')}
                  </StyledInlineButton>
                )}
              </StyledIconItem>
            </>
          )}
          <StyledIconItem>
            <FolderIcon size={16} />
            <p>{copyState.folder.title}</p>
            {areButtonsEnabled && (
              <StyledInlineButton
                margin
                onClick={() => setCopyState({ ...copyState, step: 'folder' })}
              >
                {t('App.Change')}
              </StyledInlineButton>
            )}
          </StyledIconItem>
          <StyledStepHeader>{viewConfig().prompt}</StyledStepHeader>
          {copyState.items.length !== 0 && (
            <Accordion
              onAccordionChange={onAccordionChange}
              activeSectionIndexes={copyState.items
                .filter(i => !i.isRemoved)
                .map((i, index) => {
                  return i.isOpen ? index : false;
                })}
            >
              {copyState.items
                .filter(i => !i.isRemoved)
                .map(item => (
                  <AccordionSection key={item.itemDetails.id}>
                    <AccordionTitle>
                      <StyledItemLink noMargin title={item.itemDetails.title}>
                        {resources.helper.getItemTypeIcon(item.itemDetails)}
                        <p>{item.itemDetails.title}</p>
                        {item.copyDetails.warning && (
                          <ExclamationMarkTriangleFIcon
                            style={{ marginLeft: '0.5rem' }}
                            size={16}
                            color="#959595"
                          />
                        )}
                      </StyledItemLink>
                    </AccordionTitle>
                    {/* ----- Edit Copy Details ---- */}
                    <StyledAccordionContent>
                      <div className="content-wrapper">
                        {item.copyDetails.warning && (
                          <StyledIconItem light className="warning">
                            <ExclamationMarkTriangleFIcon size={16} />
                            <p>{item.copyDetails.warning}</p>
                          </StyledIconItem>
                        )}
                        <Form>
                          <FormControl className="form-control">
                            <FormControlLabel htmlFor="title">
                              {t('Item.Copy.ItemTitle')}
                            </FormControlLabel>
                            <TextField
                              fullWidth
                              id="title"
                              defaultValue={item.copyDetails.defaultTitle}
                              onInput={e => {
                                item.copyDetails.title = e.target.value;
                                setCopyState({ ...copyState });
                              }}
                              error={
                                item.copyDetails.deep &&
                                !item.copyDetails.titleValid
                              }
                            />

                            {copy?.deep_supported?.[item.itemDetails.type] && (
                              <Checkbox
                                checked={!item.copyDetails.deep}
                                onChange={e => {
                                  item.copyDetails.deep = !e.target.checked;
                                  setCopyState({ ...copyState });
                                }}
                              >
                                {t('Item.Copy.CopyByReference')}
                              </Checkbox>
                            )}
                          </FormControl>
                        </Form>
                        <Button
                          className="remove-btn"
                          clear
                          small
                          red
                          onClick={() => removeItem(item)}
                        >
                          {t('Item.Copy.RemoveItem')}
                        </Button>
                      </div>
                    </StyledAccordionContent>
                  </AccordionSection>
                ))}
            </Accordion>
          )}

          {/* ----- Undo Notice ---- */}
          {copyState.removedItem && (
            <StyledAlert
              showCloseLabel
              onClose={() => {
                copyState.removedItem = null;
              }}
            >
              <AlertMessage>
                {`${t('Item.Copy.ItemRemoved')} : "${
                  copyState.removedItem.itemDetails.title
                }"`}
                <StyledInlineButton
                  style={{ marginLeft: '0.5rem' }}
                  small
                  onClick={undo}
                >
                  {t('App.Undo')}
                </StyledInlineButton>
              </AlertMessage>
            </StyledAlert>
          )}
        </>
      )}

      {/* ----- Pending, Success, and Failure States ---- */}
      {copyState.step === 'review' && (
        <>
          {copyState.submitStatus === 'PENDING' && (
            <StyledAlert
              icon={
                <Loader
                  sizeRatio={0.4}
                  style={{ display: 'inline-block', marginRight: '1.5rem' }}
                />
              }
            >
              <AlertMessage>
                {t('Item.Copy.CopyingItems', {
                  count: copyState.items.length
                })}
              </AlertMessage>
            </StyledAlert>
          )}
          {copyState.successMessage}
          {copyState.errorMessage}
        </>
      )}

      {/* ----- Action Buton ---- */}
      <Button
        disabled={
          !areButtonsEnabled ||
          (copyState.step === 'account' && !copyState?.account?.token)
        }
        fullWidth
        onClick={viewConfig().action}
        style={{ margin: '1.55rem 0' }}
      >
        {viewConfig().actionLabel}
      </Button>
    </>
  );
};

export default CopyItems;
