// Framework and third-party non-ui
import styled from 'styled-components';

const StyledMoreDetails = styled.a`
  font-size: var(--calcite-font-size--1);
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
`;

const StyledDetails = styled.p`
  font-size: var(--calcite-font-size--2);
`;

export { StyledMoreDetails, StyledDetails };
