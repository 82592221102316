import React, { createContext, useContext, useState } from 'react';

export const UIContext = createContext();

export const UIContextProvider = ({ children }) => {
  // ----- Accounts Drawer -----
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);
  const openAccountDrawer = () => {
    setIsAccountDrawerOpen(true);
  };
  const closeAccountDrawer = () => {
    setIsAccountDrawerOpen(false);
  };

  // ----- ItemDrawer State -----
  const [itemDrawerState, setItemDrawerState] = useState({
    isOpen: false,
    view: null, // in ['ItemDetails', 'CopyItems']
    items: []
  });
  const openItemDrawer = ({
    items = itemDrawerState.items,
    view = 'ItemDetails',
    onActionComplete
  }) => {
    setItemDrawerState({
      items,
      view,
      isOpen: true,
      onActionComplete
    });
  };
  const closeItemDrawer = () => {
    setItemDrawerState({
      ...itemDrawerState,
      isOpen: false
    });
  };

  // ----- Theme -----

  // ----- Enterprise Login View -----
  const [isEnterpriseSignIn, setIsEnterpriseSignIn] = useState(false);
  const openEnterpriseSignIn = () => setIsEnterpriseSignIn(true);
  const closeEnterpriseSignIn = () => setIsEnterpriseSignIn(false);

  return (
    <UIContext.Provider
      value={{
        openAccountDrawer,
        closeAccountDrawer,
        isAccountDrawerOpen,
        openItemDrawer,
        closeItemDrawer,
        itemDrawerState,
        setItemDrawerState,
        isEnterpriseSignIn,
        openEnterpriseSignIn,
        closeEnterpriseSignIn
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => useContext(UIContext);
