import { useState, useReducer, useCallback } from 'react';
import { getInitialStore } from '../store/store';
import reducer from '../store/reducer';
import types from '../store/types';
import {
  filterGroupTitles,
  filterItemTitles,
  filterOptionItemTitles
} from '../utils/filterGroupData';
import {
  addItemResource,
  getItemResources,
  removeItemResource,
  updateItemResource
} from 'arcgis-item-engine';
import { useItemContext } from '../../../../contexts/ItemContext';

const useItemResourcesState = itemResourceStateOptions => {
  const {
    customFilterGroupTitles,
    customFilterItemTitles,
    customTableColumnConfig,
    userAccount,
    urlSearchParams,
    onUrlParamsUpdated
  } = itemResourceStateOptions;

  const { itemDescription } = useItemContext();

  const initialStore = getInitialStore({
    filterGroups:
      customFilterGroupTitles && customFilterItemTitles
        ? customFilterGroupTitles
        : filterGroupTitles,
    filterItems:
      customFilterGroupTitles && customFilterItemTitles
        ? customFilterItemTitles
        : filterItemTitles,
    filterItemOptions:
      !customFilterGroupTitles || !customFilterItemTitles
        ? filterOptionItemTitles
        : []
  });

  const [itemResourcesState, dispatchItemResources] = useReducer(
    reducer,
    initialStore
  );
  const [itemResourcesResults, setItemResourcesResults] = useState({});
  const [selectedResourceTitles, setSelectedResourceTitles] = useState([]);

  const [sortField, setSortField] = useState(
    urlSearchParams?.get('sortField') || 'modified'
  );

  const [sortOrder, setSortOrder] = useState(
    urlSearchParams?.get('sortOrder') || 'desc'
  );

  const [urlParams, setUrlParams] = useState(urlSearchParams);

  const defaultUrlParamKeys = ['num', 'start', 'sortField', 'sortOrder'];

  const [selectedItemId, setSelectedItemId] = useState(null);

  // Checks if particular query param is different from url param value for said query param value
  const queryParamHasChanged = useCallback(
    (key, value) => {
      if (urlParams?.get && urlParams.get(key) !== value) {
        return true;
      } else if (urlParams?.[key] !== value) {
        return true;
      }

      return false;
    },
    [urlParams]
  );

  const urlParamsUpdated = useCallback(
    newParams => {
      if (urlSearchParams && onUrlParamsUpdated) {
        Object.entries(newParams).forEach(([key, value]) => {
          // If particular param value is not null, set it to URL params
          if (value !== null) {
            // If param already exists, remove existing param value before appending updated value.
            if (urlSearchParams.has(key)) {
              urlSearchParams.delete(key);
            }

            // Append param to urlSearchParams
            urlSearchParams.append(key, value);
          } else if (!defaultUrlParamKeys.includes(key)) {
            // If particular param value is null and is not one of the default URL params that should always be visible, remove said param from URL params
            urlSearchParams.delete(key);
          }
        });

        // Check to see if urlSearchParams contain all required params specified in defaultUrlParamKeys and that urlSearchParams isn't a duplicate of current window.location.search before setting new urlSearchParams
        let urlParamsReadyToUpdate = true;

        const urlParamsHaveChanged =
          `?${urlSearchParams.toString()}` !== window.location.search;

        defaultUrlParamKeys.forEach(defaultParam => {
          if (!urlSearchParams.has(defaultParam)) {
            urlParamsReadyToUpdate = false;
          }
        });

        if (urlParamsReadyToUpdate && urlParamsHaveChanged) {
          onUrlParamsUpdated(urlSearchParams);
          setUrlParams(newParams);
        }
      }
    },
    [defaultUrlParamKeys, onUrlParamsUpdated, urlSearchParams]
  );

  // Gets all available resources for item with given id
  const handleGetItemResources = async ({
    id,
    start,
    num,
    sortBy,
    sortDirection
  }) => {
    let results = {};

    if (userAccount) {
      try {
        /* The 'allItemsVisibleOnSinglePage' variable checks to see if 'start' param 
          should be automatically set to 1 in order to display total 
          number of items on single page.  If user selects a page higher than 1
          in pagination and then changes the number of items visible per page to a number
          greater than the total items, all the items should be displayed on 1 page and
          the URL params should reflect this. */

        const allItemsVisibleOnSinglePage =
          itemResourcesResults?.total < itemResourcesResults.num ? true : false;

        if (id) {
          setSelectedItemId(id);
        }

        let params = {
          sortField: sortBy || sortField,
          sortOrder: sortDirection || sortOrder,
          num:
            num ||
            (urlParams?.get && urlParams?.get('num')) ||
            urlParams?.num ||
            20,
          // Default start value is 1. This is to prevent start value from being assigned value of previous start when changing num param value.
          start:
            start ||
            (allItemsVisibleOnSinglePage &&
              urlParams?.get &&
              urlParams?.get('start')) ||
            (allItemsVisibleOnSinglePage && urlParams?.start) ||
            1
        };

        let updatedUrlParams = {
          num: params.num,
          start: params.start,
          sortField: params.sortField,
          sortOrder: params.sortOrder
        };

        // If id is provided by this function, use it as id param, otherwise use already selectedItemId

        const itemResourcesResult = await getItemResources({
          id: id || selectedItemId,
          authentication: userAccount?.session || userAccount,
          params
        });

        if (itemResourcesResult?.resources.length) {
          // Checks to see if query params have changed to see which url params need to be updated
          Object.entries(updatedUrlParams).forEach(([key, value]) => {
            if (queryParamHasChanged(key, value)) {
              updatedUrlParams[key] = value;
            }
          });

          if (sortBy) setSortField(sortBy);
          if (sortDirection) setSortOrder(sortDirection);

          // If custom urlParamsUpdated function exists, use that to update URL params.  Otherwise, use default urlParamsUpdated.

          itemResourceStateOptions.urlParamsUpdated
            ? itemResourceStateOptions.urlParamsUpdated(updatedUrlParams)
            : urlParamsUpdated(updatedUrlParams);

          // Change key name of itemResourcesResult form "resources" to "results" before setting to state. This allows itemResourcesResult to be passed to ItemTable by formatting it in accordance with queryResults returned from useItemBrowserState.
          results = {
            ...itemResourcesResult,
            results: itemResourcesResult.resources,
            id
          };

          delete results.resources;
        } else {
          results = {};
        }
        setItemResourcesResults(results);
        return results;
      } catch (error) {
        console.warn(`ERROR: ${error}`);
      }
    }
  };

  // Adds a file as a resource for item with given id
  const handleAddItemResource = async ({ file, id, fileToUploadPrefix }) => {
    let returnResult = null;

    if (userAccount && file) {
      try {
        const itemParams = {
          id,
          authentication: userAccount?.session || userAccount,
          owner: itemDescription.owner
        };

        const itemResourcesParams = {
          resource: file,
          name: file.name,
          prefix: fileToUploadPrefix
        };

        returnResult = await addItemResource(itemParams, itemResourcesParams);
      } catch (error) {
        console.warn(`ERROR: ${error}`);
        // Check error message to see if file has already been added.  If so, invoke handleReplaceItemResource()
        returnResult = error.originalMessage;
      }
    }
    return returnResult;
  };

  // Replaces file of given name with given file for item with given id
  const handleReplaceItemResource = async ({ file, id, name }) => {
    let returnResult = null;

    if (userAccount && file && name) {
      try {
        const itemParams = {
          id,
          authentication: userAccount?.session || userAccount,
          owner: itemDescription.owner
        };
        const pathSegments = name.split('/');
        const fileName = pathSegments.pop();
        const prefix = pathSegments.join('/');

        const update = {
          resource: file,
          name: fileName,
          prefix
        };

        returnResult = await updateItemResource(itemParams, update);
      } catch (error) {
        console.warn(`ERROR: ${error}`);
        returnResult = error.originalMessage;
      }
    }

    return returnResult;
  };

  // Deletes resource of given name from item with given id
  const handleDeleteItemResource = async ({ id, resourceName }) => {
    let returnResult = null;

    if (userAccount) {
      try {
        const itemParams = {
          id,
          authentication: userAccount?.session || userAccount,
          owner: itemDescription.owner
        };

        const itemResourcesParams = {
          resource: resourceName
        };

        returnResult = await removeItemResource(
          itemParams,
          itemResourcesParams
        );
      } catch (error) {
        console.warn(`ERROR: ${error}`);
        returnResult = error.originalMessage;
      }
    }

    return returnResult;
  };

  // Called by handleItemResourcesStateUpdate(), sets state via useReducer()
  const onStateChange = updatedState => {
    if (updatedState) {
      dispatchItemResources({
        type: types.UPDATE_ITEM_RESOURCES_STATE_MAIN,
        updatedState
      });
    }
  };

  // Called anytime state updates
  const handleItemResourcesStateUpdate = async stateUpdates => {
    try {
      let updatedState = stateUpdates
        ? { ...itemResourcesState, ...stateUpdates }
        : { ...itemResourcesState };

      if (stateUpdates) {
        onStateChange(updatedState);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return {
    itemResourcesState,
    handleItemResourcesStateUpdate,
    customTableColumnConfig,
    handleGetItemResources,
    itemResourcesResults,
    handleAddItemResource,
    handleReplaceItemResource,
    handleDeleteItemResource,
    selectedResourceTitles,
    setSelectedResourceTitles,
    sortField,
    sortOrder
  };
};

export default useItemResourcesState;
