// Framework and third-party non-ui
import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserContent } from '@esri/arcgis-rest-portal';

// Hooks, context, and constants
import { AccountsContext } from 'contexts/AccountsContext';
import { useUIContext } from 'contexts/UIContext';
import { useItemContext } from 'contexts/ItemContext';

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import SubNav, {
  SubNavList,
  SubNavLink,
  SubNavTitle
} from 'calcite-react/SubNav';
import Breadcrumbs, { Crumb } from 'calcite-react/Breadcrumbs';

const ItemSubNav = ({ paths, queryScope }) => {
  // ----- Language -----
  const { t } = useTranslation();
  const langRef = 'Pages.Item.SubPages';

  // ----- Item Drawer / Details -----
  const { isContextReady, itemDescription } = useItemContext();
  const { openItemDrawer, itemDrawerState } = useUIContext();

  // -----  Account -----
  const { selectedAccount } = useContext(AccountsContext);

  // -----  Routing -----
  const { pathname } = useLocation();
  const [currentFolder, setCurrentFolder] = useState(false);

  useEffect(() => {
    // Attempt to get item folder
    isContextReady &&
      itemDescription.ownerFolder &&
      getUserContent({
        owner: itemDescription.owner,
        folderId: itemDescription.ownerFolder,
        num: 0,
        authentication: selectedAccount.session
      })
        .then(res => setCurrentFolder(res.currentFolder))
        .catch(err => console.error(err));

    // Clear folder breadcrumb before unmount
    return () => {
      setCurrentFolder(false);
    };
  }, [isContextReady]);

  let breadCrumbText;
  switch (queryScope) {
    case 'ORGANIZATION':
      breadCrumbText = t('Pages.Content.SubPages.OrgContent.Title');
      break;
    case 'ONLINE':
      breadCrumbText = t('Pages.Content.SubPages.AGOContent.Title');
      break;
    case 'USER':
    default:
      breadCrumbText = t('Pages.Content.SubPages.UserContent.Title');
      break;
  }

  return (
    <SubNav backgroundColor="var(--assistant-green)">
      <SubNavTitle>
        <Breadcrumbs dividerCharacter="›">
          <Crumb
            as={Link}
            to={`/content?queryScope=${queryScope || 'USER'}`}
            hasLink
          >
            {breadCrumbText}
          </Crumb>
          {currentFolder && isContextReady && (
            <Crumb
              style={{ animation: 'fadeInAndRight 0.2s' }}
              as={Link}
              to={`/content?queryScope=USER&folder=${itemDescription.ownerFolder}`}
              hasLink
            >
              {currentFolder.title}
            </Crumb>
          )}
          {isContextReady && (
            <Crumb style={{ animation: 'fadeInAndRight 0.2s' }}>
              {itemDescription?.title}
            </Crumb>
          )}
        </Breadcrumbs>
      </SubNavTitle>
      <SubNavList>
        <SubNavLink
          as={Link}
          to={paths.json}
          active={pathname.includes(paths.json)}
        >
          {t(`${langRef}.Json.Title`)}
        </SubNavLink>
        <SubNavLink
          as={Link}
          to={paths.resources}
          active={pathname.includes(paths.resources)}
        >
          {t(`${langRef}.Resources.Title`)}
        </SubNavLink>
        <SubNavLink
          active={itemDrawerState.isOpen}
          onClick={() => openItemDrawer({ items: [itemDescription] })}
        >
          {t(`${langRef}.ItemDetails.Title`)}
        </SubNavLink>
      </SubNavList>
    </SubNav>
  );
};

export default ItemSubNav;
