import types from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case types.UPDATE_ITEM_RESOURCES_STATE_MAIN:
      if (action.updatedState) {
        return action.updatedState;
      }
      return state;

    default:
      return state;
  }
};

export default reducer;
