// Framework and third-party non-ui
import React from 'react';
import { useTranslation } from 'react-i18next';

// JSON & Styles
import { StyledEmptyState } from './ItemEditorEmptyState-styled';

// Third-party components (buttons, icons, etc.)
import BracketsCurlyIcon from 'calcite-ui-icons-react/BracketsCurlyIcon';

const ItemEditorEmptyState = ({ type }) => {
  const { t } = useTranslation();
  const title = t(`Pages.Item.SubPages.EmptyStates.${type}.title`);

  const description = t(`Pages.Item.SubPages.EmptyStates.${type}.description`);

  return (
    <StyledEmptyState>
      <BracketsCurlyIcon color="gray" size={80} />
      <h1>{title}</h1>
      <p>{description}</p>
    </StyledEmptyState>
  );
};

export default ItemEditorEmptyState;
