// Framework and third-party non-ui
import styled, { css } from 'styled-components';

// Third-party components (buttons, icons, etc.)
import { CalciteA } from 'calcite-react/Elements';
import { AccordionContent } from 'calcite-react/Accordion';
import Alert from 'calcite-react/Alert';
import Card from 'calcite-react/Card';
import Button from 'calcite-react/Button';

const StyledItemLink = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props =>
    !props.noMargin ? '1.5rem' : '0'};
  img,
  svg {
    margin-inline-end: 0.25rem;
  }
  a,
  p {
    ${props => props.small && 'font-size: 14px'};
    margin: 0;
    line-height: 1.2;
    ${props =>
      !props.allowOverflow &&
      css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
      `}
  }
`;

const StyledStepHeader = styled.h2`
  font-weight: var(--calcite-font-weight-normal);
  font-size: 14px;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  color: var(--calcite-ui-text-3);
  border-bottom: 1px solid var(--calcite-ui-border-3);
`;

const StyledStepPrompt = styled.p`
  font-size: 14px;
  color: var(--calcite-ui-text-1);
  margin: 1.25rem 0 0.25rem 0;
`;

const StyledInlineButton = styled(CalciteA)`
  font-size: var(--calcite-font-size--1);
  margin: ${props => (props.margin ? '0.25rem 0 0 0' : 0)};
  color: var(--calcite-ui-brand);
  display: inline-flex;
  align-items: center;
`;

const StyledIconItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size:var(--calcite-font-size--1);
  p {
    margin: 0 0 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    margin: 0 0 0 auto;
  }
  ${props => props.light && { color: 'var(--calcite-ui-text-3)' }}
`;

const StyledAccordionContent = styled(AccordionContent)`
  font-size: var(--calcite-font-size--1); !important;
  .content-wrapper {
    display: flex;
    flex-direction: column;
  }
  .warning {
    margin-bottom: 1rem;
  }
  .form-control {
    margin-left: 0;
    margin-right: 0;
  }
  .remove-btn {
    margin-left: auto;
  }
`;

const StyledAlert = styled(Alert)`
  font-size: var(--calcite-font-size--1);
  margin-top: 1rem;
`;

const StyledAccountStep = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledWarningCard = styled(Card)`
  margin-top: 1.25rem;
`;

const StyledReAuthButton = styled(Button)`
  align-self: flex-start;
  padding-left: 0;
`;

export {
  StyledItemLink,
  StyledStepHeader,
  StyledStepPrompt,
  StyledInlineButton,
  StyledIconItem,
  StyledAccordionContent,
  StyledAlert,
  StyledAccountStep,
  StyledWarningCard,
  StyledReAuthButton
};
