// Framework and third-party non-ui
import React from 'react';

// Hooks, context, and constants

// App components

// JSON & Styles
import { StyledToolbarWrapper, StyledToolbar } from './Toolbar-styled';

// Third-party components (buttons, icons, etc.)

const Toolbar = ({ centerContent, leftContent, rightContent }) => {
  // ----- Language -----

  return (
    <StyledToolbarWrapper>
      <StyledToolbar>
        {leftContent && <div className="col-1">{leftContent}</div>}
        <div className="col-2">{centerContent}</div>
        {rightContent && <div className="col-3">{rightContent}</div>}
      </StyledToolbar>
    </StyledToolbarWrapper>
  );
};

export default Toolbar;
