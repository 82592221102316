// Framework and third-party non-ui
import styled from 'styled-components';

export const StyledEmptyState = styled.div`
  position: relative;
  top: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
    font-weight: var(--calcite-font-weight-normal);
    line-height: 0;
    margin: 2rem 0 0.5rem 0;
    font-size: ${props => props.theme.baseline};
    color: ${props => props.theme.palette.offWhite};
  }
  p {
    text-align: center;
    font-weight: var(--calcite-font-weight-normal);
    line-height: 1.5;
    color: ${props => props.theme.palette.lightGray};
    width: ${props => props.theme.small};
  }
`;
